import { CatchingPokemonSharp, InstallDesktopRounded } from '@mui/icons-material';
import config from '../config/config';
import React,{createContext, useState, useContext} from 'react';
import { NotificationManager } from 'react-notifications';
import { setAvailableInstallerData, sortInstallers } from './Installer';
import { Button } from 'reactstrap';
import { authFetchHook } from './AuthenticatedFetch';
import { useSelector } from 'react-redux';
import { contextType } from 'react-autosuggest';
import { useCreateScheduleContext } from './CreateScheduleProvider';
import { useScheduleContext } from './ScheduleProvider';
import { integerPropType } from '@mui/utils';
import moment from 'moment';

const installerTemplate = {
    installers: [],
    installerSchedules: [],
    availableInstallers: null,
    selectedInstallers: [],
    selectedInstallerData: null,
    showInstallerSchedules: false,
    getInstallersForDay: async() =>{},
    sortInstallers: () => {},
    getAvailableInstallerData: () => {}, 
    getInstallerSchedules: async() => {},
    toggleInstallerModal: () => {},
    setAvailableInstallers: () => {},
    removeSelectedInstaller:() => {},
    addSelectedInstaller:() => {},
    filterAvailable:() => {},
    setSelectedForman:() => {},
    setScheduledInstallerData: () => {},
    toggleShowInstallerSchedules: () => {},
    clearSelectedInstallers: () => {},
    clearAvailableInstallerData: () => {},
    setSelectedInstallers: () => {}
    
}

export const InstallerContext = createContext(installerTemplate);

export const useInstallerContext = () => useContext(InstallerContext)

export const InstallerProvider = ({children}) => {
    const [installers,setInstallers] = useState([]);
    const [sortDirection, setSortDirection] = useState("ASC")
    const [availableInstallers, setAvailableInstallers] = useState(null)
    const [installerSchedules, setInstallerSchedules] = useState([])
    const [showInstallerModal, setShowInstallerModal] = useState(false)
    const[selectedInstallers, setSelectedInstallers] = useState([])
    const[selectedInstallerData, setSelectedInstallerData] = useState(null)
    const [showInstallerSchedules, setShowInstallerSchedules] = useState(null)
    const user = useSelector(state => state.auth)
    const {schedDate} = useScheduleContext()
    
    const getInstallersForDay = async(date) => {

        let stops = await authFetchHook(user.authUser,`${config.apiPath}/schedule/installerstops/` + date,"GET").then(json => {
           return json
          })

          let installers = await authFetchHook(user.authUser,`${config.apiPath}/schedule/installers`, "GET").then(json => {
            return json
          });
          if(stops) {
          for(let i =0; i < stops.length; i++) {
            for(let y =0; y < installers.length; y++) {
              if(stops[i].InstallerId === installers[y].id) {
                installers[y]["stops"] = stops[i].stops;
              }
            }
          }
         }
         setInstallers(installers)
    }

    const toggleInstallerModal  = () => {
      setShowInstallerModal(!showInstallerModal)
    }

    const toggleShowInstallerSchedules = () => {
      setShowInstallerSchedules(!showInstallerSchedules)
    }

    const sortInstallers = (sortBy) => {

      setSortDirection((sortBy && sortBy === sortBy && sortDirection === "ASC") ? "DESC" : "ASC")
          if (sortDirection === 'ASC') {
            installers.sort((a, b) => {
      
              if (a[sortBy] > b[sortBy]) {
                return 1
              }
      
              if (a[sortBy] < b[sortBy]) {
                return -1
              }
      
            })
      
          } else {
            installers.sort((a, b) => {
      
              if (a[sortBy] < b[sortBy]) {
                return 1
              }
      
              if (a[sortBy] > b[sortBy]) {
                return -1
              }
      
            })
          }
          setInstallers(installers)

    }

   const getAvailableInstallerData = ( addFunc, viewSchedFunc, schedDate) => {

      if(!installers) {
        return null;
      }
      let fileteredInstallers = null
      if(selectedInstallers.length > 0) {
        let idArr = []
        selectedInstallers.forEach(selInst => idArr.push(selInst.id))
      

      fileteredInstallers = []
      installers.forEach(ins => {
        if(!idArr.includes(ins.id)) {
          fileteredInstallers.push(ins)
        }
      })
       }else {
        fileteredInstallers = installers
       }

      
    
      fileteredInstallers.forEach(installer => {
        installer.add = <Button onClick={(e) => {
          addFunc(installer.id)
        }}>Add</Button>;

        if(installer.stops) {
          installer.stpButton = <Button onClick={(e) => viewSchedFunc(installer.id, encodeURIComponent(moment(schedDate ? schedDate: new Date()).format('MM/DD/YYYY')))}>{installer.stops}</Button>
        }else {
          installer.stpButton = <div></div>
        }
      
      })
  
    let installerData = {
      columns: [
        {
          label: 'ID',
          field: 'id',
          sort: 'asc',
          width: 150
        },
        {
          label: 'Name',
          field: 'name',
          sort: 'asc',
          width: 150
        },
        {
          label: 'Rank',
          field: 'rank',
          sort: 'asc',
          width: 150
        },
        {
          label: 'Email',
          field: 'email',
          sort: 'asc',
          width: 150
        },
        {
          label: 'Stops',
          field: 'stpButton',
          sort: 'asc',
          width: 60
        },
        {
          label: 'Notes',
          field: 'notes',
          sort: 'asc',
          width: 300
        },
        {
          label: '',
          field: 'add'
        }
      ],
      rows: fileteredInstallers
    }
    setAvailableInstallers(installerData);
  
  }

  const clearSelectedInstallers = () => {
    setSelectedInstallers((selectedInstallers) => [])
    setAvailableInstallerData(null)
  }

  const setScheduledInstallerData = () => {
    if(!selectedInstallers && !installers) {
      return null;
    }
  
    var selected = []
    installers.map((inst) =>  {
      selectedInstallers.forEach(ss => {
        if(inst.id === ss.id) {
          inst.add = null
          selected.push(inst)
        }
      })  
    })

  let installerData = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Rank',
        field: 'rank',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Stops',
        field: 'stops',
        sort: 'asc',
        width: 60
      },
      {
        label: 'Notes',
        field: 'notes',
        sort: 'asc',
        width: 300
      },
      {
        label: '',
        field: 'add'
      }
    ],
    rows: selected
  }
    setSelectedInstallerData(installerData);
  } 

  const addSelectedInstaller = (id) => {
    installers.forEach((inst) => {
      if (inst.id === id) {
          setSelectedInstallers(selectedInstallers => [...selectedInstallers, inst])
          
      }
  })
  
}

const filterAvailable = (id) => {
   console.log('Tyring to filter ')

   if(!availableInstallers) 
      getAvailableInstallerData(()=>{addSelectedInstaller},()=>{},schedDate)
    var flterRows  =  availableInstallers.rows.filter(rw=> {rw.id !== id})
    let avail  = availableInstallers
    avail.rows = flterRows
    setAvailableInstallers(avail)
  
}

  const getInstallerSchedules = async(id,date) => {
    let installerScheds = await authFetchHook(user.authUser, `${config.apiPath}/schedule/installerData/${id}/${date}`, "GET").then(json => {
      return json
       });
       
       setInstallerSchedules(installerScheds)
       return true
  }

  const removeSelectedInstaller =(id) => {
    let filtered = selectedInstallers.filter(fints => fints.id !== id )
    setSelectedInstallers(filtered)
 }

 const setSelectedForman = (id) => {
  setSelectedInstallers((selections) => selections.map((installer) => (installer.id === id ? { ...installer, isForeman: true } : {...installer, isForeman: false})))

 } 

 const clearAvailableInstallerData = () => {
  setAvailableInstallerData(null)
 }

  

   


    
    return (<InstallerContext.Provider value={{installers,getInstallersForDay, sortInstallers, availableInstallers,getAvailableInstallerData, installerSchedules, getInstallerSchedules, toggleInstallerModal, showInstallerModal,setAvailableInstallers, removeSelectedInstaller,addSelectedInstaller, selectedInstallers, filterAvailable, setSelectedForman, selectedInstallerData, setScheduledInstallerData,showInstallerSchedules, toggleShowInstallerSchedules,clearSelectedInstallers,clearAvailableInstallerData,setSelectedInstallers }}>
        {children}
    </InstallerContext.Provider>)
}