import {applyMiddleware, compose, createStore} from 'redux';
import {createBrowserHistory} from 'history';
import createRootReducer from '../reducers/index';
import {routerMiddleware} from 'connected-react-router';
import thunk, { } from 'redux-thunk';


export const history = createBrowserHistory();

export default function configureStore(initialState) {
    const store = createStore(createRootReducer(history), initialState,
        compose(applyMiddleware(routerMiddleware(history),thunk)));

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers/index', () => {
            const nextRootReducer = require('../reducers/index');
            store.replaceReducer(nextRootReducer);
        });
    }
    return store;
}
 