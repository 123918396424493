import {
    GET_INSTALLERS,
 SELECT_INSTALLER,
 EDIT_INSTALLER,
 AVAIL_INSTALLERS,
 SELECTED_INSTALLERS,
 AVAIL_INSTALLER_DATA,
 SCHED_INSTALLER_DATA,
 INSTALLER_STOPS,
 INSTALLER_SCHEDULES,
 WORKING_INSTALLER,
 WORKING_INSTALLER_DATA,
 RESET_INSTALLERS,
 INSTALLER_EDIT_LIST,
} from 'constants/ActionTypes';

const INIT_STATE = {
    allInstallers:  null,
    availInstallers: null,
    availInstallerData: null,
    workingInstallers: null,
    workingInstallerData: null,
    selectedInstaller: {},
    scheduledInstallerData: null,
    editInstaller: {},
    selectedInstallers: null,
    installerStops: [],
    installerScheds: [],
    installerEditList: {},
};

const installers = (state = INIT_STATE, action) => {
    switch(action.type) {
        case GET_INSTALLERS: {
            return {
                ...state,
                allInstallers: action.payload
            }
        }

        case SELECT_INSTALLER: {
            return {
                ...state,
                selectedInstaller: action.custName
            }
        }
        case  EDIT_INSTALLER: {
            return {
                ...state,
                editInstaller: action.payload
            }
        }
        case AVAIL_INSTALLERS: {

            return {
                ...state,
                availInstallers: action.payload
            }
        }
        case SELECTED_INSTALLERS: {
            return {
                ...state,
                selectedInstallers: action.payload

            }
        }
        case AVAIL_INSTALLER_DATA: {
            return {
                ...state,
                availInstallerData: action.payload
            }
        }
        case SCHED_INSTALLER_DATA: {
            return {
                ...state,
                scheduledInstallerData: action.payload
            }
        }
        case INSTALLER_STOPS: {
            return {
              ...state,
              installerStops: action.payload
            }
        }
        case RESET_INSTALLERS: {
            return {
                ...state,
                selectedInstallers: state.availInstallers
            }
        }
        case INSTALLER_SCHEDULES: {
            return {
                ...state,
                installerScheds: action.payload
            }
        }
        case WORKING_INSTALLER: {
            return {
                ...state,
                workingInstallers: action.payload
            }
        }
        case WORKING_INSTALLER_DATA: {
            return {
                ...state,
                workingInstallerData: action.payload
            }
        }
        case INSTALLER_EDIT_LIST: {
            return {
                ...state,
                installerEditList: action.payload 
            }
        }
        default:
        return state;
    }
}

export default installers;