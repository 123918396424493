
import config from '../config/config';
import { 
    GET_USERS, 
    GET_USER_BY_ID, 
    UPDATE_FORM, 
    CLEAR_USER_FORM,
    } from 'constants/ActionTypes';
import {NotificationManager} from 'react-notifications'; 
import { authenticatedFetch } from './AuthenticatedFetch';

export function editUserById(userId) {
    return (dispatch, getState) => {
        let state = getState();
        
        authenticatedFetch(state, `${config.apiPath}/users/${userId}`, "GET")    
        .then(response => {
            return response.json().then(user => ({
                data: user,
                status: response.status
            }));
        })
        .then(response => {
            if (response.status === 200) {
                
                dispatch({type: GET_USER_BY_ID, payload: response.data});
            } else {
                NotificationManager.error('Failed','Error getting User data', 2000);
            }
        })
        .catch(err => { console.log(err); });
    };
}

export function createUser(user) {
    return (dispatch, getState) => {
        let state = getState();
        let body = JSON.stringify(user);
        
        authenticatedFetch(state, `${config.apiPath}/users/`, "POST", body)
        .then(response => {
            if (response.status === 200) {
                NotificationManager.success('', 'User Created Sucessfully', 2000);
            } else if (response.status === 403) {
                NotificationManager.error('Failed','Your account does not have the required permissions to create a user.', 2000);
            } else {
                NotificationManager.error('Failed', 'Error occured attempting to create a user', 2000);
            }
        })
        .catch(err => { console.log(err); });
    };
}

export function updateUser(user) {
    return (dispatch, getState) => {
        let state = getState();
        let body = JSON.stringify(user);
        
        authenticatedFetch(state, `${config.apiPath}/users/${user.id}`, "PUT", body)
        .then(response => {
            if (response.status === 200) {
                NotificationManager.success('', 'User Updated Sucessfully', 2000);
            } else if (response.status === 403) {
                NotificationManager.error('Failed','Your account does not have the required permissions to update a user.', 2000);
            } else {
                NotificationManager.error('Failed', 'Error occured attempting to update a user', 2000);
            }
        })
        .catch(err => { console.log(err); });
    };
}

export function getUsers() {
    return (dispatch, getState) => {
        let state = getState();
        authenticatedFetch(state, `${config.apiPath}/users/`, "GET")
            .then(response => {
                return response.json().then(users => ({
                    data: users,
                    status: response.status
                }));
            })
            .then(response => {
                if (response.status === 200) {
                    dispatch({type: GET_USERS, payload: response.data});
                } else {
                    NotificationManager.error('Failed','Error getting Users', 2000);
                }
            })
            .catch(err => { console.log(err); });
    };
}

export function updateUserForm(user) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_FORM,
            payload: user
        });
    };
}

export function clearUserForm() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_USER_FORM,
            payload: {}
        });
    };
}