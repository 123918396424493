import { CatchingPokemonSharp, HourglassBottom, InstallDesktopRounded, SubscriptionsOutlined } from '@mui/icons-material';
import config from '../config/config';
import React,{createContext, useState, useContext} from 'react';
import { NotificationManager } from 'react-notifications';
import { sortInstallers } from './Installer';
import { useInstallerContext } from './InstallerProvider';
import { isTablet } from 'react-device-detect';
import { authenticatedFetch, authFetchHook } from './AuthenticatedFetch';
import { useSelector } from 'react-redux';
import { at } from 'lodash';
import { renderField } from '../util/QuoteFormUtil';
import { Input } from 'reactstrap';
import moment from 'moment';

const createScheduleTemplate = { 
    color: '',
    attachments: null,
    showAvailableInstallers: false,
    showScheduledInstallers: false,
    showInstallerSchedule: false,
    selectedInstallers: [],
    hour: null,
    minute: null,
    ampm: null,
    startTime: null,
    isNewSave: false,
    notes: null,
    driverName: null,
    driverNumber: null,
    hours:[],
    minutes:[],
    ampms:[],
    dates: [],
    toggleShowAvailableInstallers: () => {},
    toggleShowScheduledInstallers: () => {},
    toggleShowInstallerSchedule: () => {},
    setSelectedInstallers: () => {},
    setColor: () => {},
    sethour: () => {},
    setMinute:() => {},
    setAmpm:() => {},
    setStartTime:() => {},
    addSelectedInstaller:() => {},
    addAttachment:() =>{},
    removeAttachment:() => {},
    createNewSchedule:() => {},
    setIsNewSave:() => {},
    createNewSave:() => {},
    setNotes: () => {},
    setDriverName: () => {},
    setDriverNumber: () =>{},
    renderField: () => {},
    renderReadOnlyField: () => {},
    renderEditField: () => {},
    getAttachments: () => {},
    addDate: () => {},
    removeDate: () => {},
    resetData: () => {},
    saveAttachments: () => {},
    getSavedAttachments:() => {},

    

}

export const CreateScheduleContext = createContext(createScheduleTemplate);

export const useCreateScheduleContext = () => useContext(CreateScheduleContext)

export const CreateScheduleProvider = ({children}) => {

  const {setSelectedInstallers} = useInstallerContext()
   
   const[color,setColor] = useState('')
   const[attachments,setAttachments] = useState([])
   const[showAvailableInstallers,setShowAvailableInstallers] = useState(false)
   const[showScheduledInstallers, setShowScheduledInstallers] = useState(false)
   
   const[showInstallerSchedule,setShowInstallerSchedule] = useState([])
   const[installerSchedule, setInstallerSchedule] = useState([])
   const[hour,setHour] = useState(null)
   const[minute, setMinute] = useState(null)
   const[ampm, setAmpm] = useState(null)
   const[startTime, setStartTime] = useState(null)
   const[isNewSave, setIsNewSave] = useState(null)
   const[notes,setNotes] = useState(null)
   const[driverName, setDriverName] = useState(null)
   const[driverNumber, setDriverNumber] = useState(null)
   const[dates, setDates] = useState([])

   const user = useSelector(state => state.auth)

   const resetData = () => {
    setDates([])
    setDriverName('')
    setDriverNumber('')
    setAmpm(null)
    setHour(null)
    setMinute(null)
    setInstallerSchedule([])
    setAttachments([])
    setStartTime(null)
    setNotes('')
    setColor('')
    

   }
   

   const toggleShowAvailableInstallers = () => {
     setShowAvailableInstallers(!showAvailableInstallers)
    
   }

   const toggleShowScheduledInstallers = () => {
      setShowScheduledInstallers(!showScheduledInstallers)
   }

   const toggleShowInstallerSchedule = () => {
    setShowInstallerSchedule(!showInstallerSchedule)
   }

   const getAttachments = async(id) => {
    let attchmnts = await authFetchHook(user.authUser, `${config.apiPath}/schedule/attachments/` + id, "GET").then( json => {return json})
    setAttachments(attchmnts)
   }

   const getSavedAttachments = async(id) => {
    let attchmnts = await authFetchHook(user.authUser, `${config.apiPath}/schedule/attachments/saved/` + id, "GET").then( json => {return json})
    setAttachments(attchmnts)
   }

   const addAttachment = (inFile) => {
      setAttachments((attachements) => [...attachments,inFile])
   }
   const removeAttachment = (fileName) => {
    console.log('removing ', fileName)
    if(attachments[0].filename) {
      let filtered = attachments.filter(att => att.filename !== fileName)
      setAttachments(filtered)
    }
    if(attachments[0].fileName) {
      let filtered = attachments.filter(att => att.fileName !== fileName)
      setAttachments(filtered)
    }
   }

   const saveAttachments = async(quoetid, quoteScheduleId, attachments) => {
    let req = {
      QuoteId: quoetid,
      QutoeScheduleId: quoteScheduleId,
      attachments: attachments
    }

    let resp = await authFetchHook(user.authUser, `${config.apiPath}/schedule/saveAttachments`, "POST", JSON.stringify(req)).then(text => {
      console.log('response was', text)
      
    })
    return resp
   }

   const createNewSchedule = async(sched,closeFunc) => {
      //await saveAttachments(sched.quoteSchedule.QuoteId, sched.attachments)
      dates.forEach(async dt => {

        let nSched = sched
        console.log('date is ',dt)
        nSched.quoteSchedule["date"] = moment(dt).format('MM/DD/YYYY')
        console.log('changed is', nSched.quoteSchedule)
        let resp = await authFetchHook(user.authUser, `${config.apiPath}/schedule/saveSched`, "POST", JSON.stringify(nSched)).then(text => {
          console.log('response was', text)
          
        })
      })
      NotificationManager.success("Schedule Saved", "Success", 2000)
      setAttachments([])
      setNotes(null)
      setDriverName(null)
      setDriverNumber(null)
      setAttachments([])
      setDates([])
      closeFunc()
      return new Promise((resolve) => resolve(true))

   }

   const createNewSave = async(sched, closeFunc) => {
    
    dates.forEach(async dt => {
      let nSched = sched
      nSched.quoteSchedule["date"] = moment(dt).format('MM/DD/YYYY')
      let savedSched = await authFetchHook(user.authUser,`${config.apiPath}/schedule/save`, "POST", JSON.stringify(sched) ).then(json => {
      })
    })
    await saveAttachments(savedSched.id, sched.attachments)
    NotificationManager.success("Schedule Saved", "Success", 2000)
    setAttachments([])
    setNotes(null)
      setDriverName(null)
      setDriverNumber(null)
      setDates([])
      setAttachments([])
    closeFunc()
    return new Promise((resolve) => resolve(true))
   }

   const renderField = ({ name, label, type, meta: { touched, error } },changeFunc) => (
    <div style={type === 'checkbox' ? { marginLeft: '30px', marginTop: '10px', marginBottom: '10px' } : {}}>
      <label>{label}</label>
      <div style={type === 'checkbox' ? { float: 'left' } : {}}>
        <Input name={name} onChange={((e)=> changeFunc(e.target.value))} placeholder={label} type={type} autoComplete='off' />
        {touched && error && <span style={{color: 'red'}}>{error}</span>}
      </div>
    </div>
  )

  const renderEditField = ({ name, label,value, type, meta: { touched, error } },changeFunc) => (
    <div style={type === 'checkbox' ? { marginLeft: '30px', marginTop: '10px', marginBottom: '10px' } : {}}>
      <label>{label}</label>
      <div style={type === 'checkbox' ? { float: 'left' } : {}}>
        <Input name={name} value={value} onChange={((e)=> changeFunc(e.target.value))} placeholder={label} type={type} autoComplete='off' />
        {touched && error && <span style={{color: 'red'}}>{error}</span>}
      </div>
    </div>
  )

  const hours = ["","01","02","03","04","05","06","07","08","09","10","11","12"];  
  const minutes = ["", "00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25", "26", "27","28", "29", "30","31", "32", "33","34", "35", "36"
  ,"37", "38", "39","40", "41", "42","43", "44", "45","46", "47", "48","49", "50", "51","52", "53", "54","55", "56", "57","58", "59"];     
  const ampms =["","AM","PM"]

  const renderReadOnlyField = ({ value,name, label, type, meta: { touched, error } }) => (
    <div style={type === 'checkbox' ? { marginLeft: '30px', marginTop: '10px', marginBottom: '10px' } : {}}>
      <label>{label}</label>
      <div style={type === 'checkbox' ? { float: 'left' } : {}}>
        <Input name={name} value={value}  placeholder={label} type={type} autoComplete='off' readonly={'readonly'} />
        {touched && error && <span style={{color: 'red'}}>{error}</span>}
      </div>
    </div>
  )

  const addDate = (dt) => {

    console.log('Adding ',dt)
    setDates(dates => [...dates, dt])
    console.log('dates are ',dates )
  }

  const removeDate = (date) => {
    setDates(dates.filter(dt => dt !== date))
  }



   return (<CreateScheduleContext.Provider value={{color,
                                                   setColor,
                                                   attachments,
                                                   showAvailableInstallers,
                                                   showScheduledInstallers,
                                                   toggleShowAvailableInstallers, 
                                                   toggleShowScheduledInstallers, 
                                                   showInstallerSchedule, 
                                                   toggleShowInstallerSchedule,
                                                   hour,
                                                   setHour,
                                                   minute,
                                                   setMinute,
                                                   ampm,
                                                   setAmpm,
                                                   startTime,
                                                   setStartTime,
                                                   createNewSchedule,
                                                   isNewSave,
                                                   setIsNewSave,
                                                   createNewSave,
                                                   notes,
                                                   setNotes,
                                                   driverName,
                                                   setDriverName,
                                                   driverNumber,
                                                   setDriverNumber, 
                                                   addAttachment,
                                                   removeAttachment,
                                                   hours,
                                                   minutes,
                                                   ampms,
                                                   renderField,
                                                   getSavedAttachments,
                                                   renderReadOnlyField, renderEditField, getAttachments, addDate,removeDate,dates, resetData, saveAttachments}}>
        {children}
    </CreateScheduleContext.Provider>)
}