import {
    GET_CUSTOMERS,
    SELECT_CUSTOMER,
    CUSTOMER_NAMES,
    EDIT_CUSTOMER,
    CUSTOMER_SAVING,
    CUSTOMER_DONE_SAVING,
    CUSTOMER_EDIT_LISTS
} from 'constants/ActionTypes';

const INIT_STATE = {
    allCustomers:  null,
    allCustomerNames: [' '],
    editCustomer: {},
    customerSaved: false,
    isCustomerSaving: false,
    customerEditList: {}
};

const customers = (state = INIT_STATE, action) => {
    switch(action.type) {
        case GET_CUSTOMERS: {
            return {
                ...state,
                allCustomers: action.payload
            }
        }

        case SELECT_CUSTOMER: {
            return {
                ...state,
                selectedCustomer: action.custName
            }
        }

        case CUSTOMER_EDIT_LISTS: {
            return { 
                ...state,
                customerEditList: action.payload
            }
        }

        case CUSTOMER_NAMES: {
            return {
                ...state,
                allCustomerNames: action.payload,
            }
        }

        case EDIT_CUSTOMER: {
            return {
                ...state,
                editCustomer: action.payload
            }
        }

        case CUSTOMER_SAVING: {
            return {
                ...state,
                isCustomerSaving: true
            }
        }

        case CUSTOMER_DONE_SAVING: {
            return {
                ...state,
                isCustomerSaving: false
            }
        }

        default:
        return state;
    }
}

export default customers;