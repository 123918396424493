import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { Label, Button, Form, FormGroup, Input } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import {CircularProgress} from '@mui/material';
import { hideMessage, showAuthLoader, userSignIn } from 'actions/Auth';
import config from '../../package.json';

class SignIn extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            btnDisabled: true,
            password: '',
            valErrors: {},
        }
    }

    componentDidUpdate() {
        if (this.props.showMessage) {
            setTimeout(() => {
                this.props.hideMessage();
            }, 100);
        }
        let { authUser } = this.props;
        if (authUser !== null && authUser.hasOwnProperty("authenticated")) {
            this.props.history.push('/');
        }
    }

    doSignIn = (email, pass) => {

        const { valErrors, } = this.state;

        if (!email) {

        }

        if (!pass) {

        }
    }

    doChangePass = (e) => {
        const { email, } = this.state;

        e.preventDefault();
        let disabled = true;
        let passMsg = '';
    
        if (String(e.target.value || "").length > 0 && email.length > 0) {
            disabled = false;
        }

        if (String(e.target.value || "").length == 0) {
            passMsg = 'Password is Required';
        }

        this.setState({ password: e.target.value, btnDisabled: disabled, passwdMsg: passMsg });
    }

    doChangeUser = (e) => {
        const { password } = this.state;

        e.preventDefault();
        let disabled = true;
        let mailmsg = '';
        if (String(e.target.value || "").length > 0 && password.length > 0) {
            disabled = false;
        }
        if (String(e.target.value || "").length == 0) {
            mailmsg = 'Email is Required';
        }

        this.setState({ email: e.target.value, btnDisabled: disabled, emailMsg: mailmsg });


    }

    render() {
        const {
            email,
            password,
            btnDisabled,
            emailMsg,
            passwdMsg,
        } = this.state;
        const { showMessage, loader, alertMessage } = this.props;
        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <div>
                            <Link className="logo-lg" to="/" title="Jambo">
                                <img src="/assets/images/midinclogo.png" style={{ marginLeft: 5, marginRight: 5 }} alt="jambo" title="jambo" />
                            </Link>
                        </div>
                        <div className="appVersion">Version: {config.version}</div>    
                    </div>
                    <div className="app-login-content">

                        <div className="app-login-form">
                            <Form>
                                <FormGroup>
                                    <Label for="signinEmail" >Email</Label>
                                    <Input type="email" name="email" id="signinEmail" onChange={(event) => this.doChangeUser(event)} defaultValue={email} id="signinEmail" />
                                    <Label style={{ color: 'red' }} > {emailMsg}</Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="signinPassword">Password</Label>
                                    <Input type="password" name="password" id="signinPassword" onChange={(event) => this.doChangePass(event)} defaultValue={password} id="signinPassword" 
                                    onKeyPress={(e)=>{
                                        if(e.key === 'Enter') {
                                            if (email.length > 0 && password.length > 0) {
                                                this.props.userSignIn({ email, password });
                                            }
                                        }
                                    }}/>
                                    <Label style={{ color: 'red' }}>{passwdMsg}</Label>
                                </FormGroup>
                                <Button onClick={() => {
                                    this.props.showAuthLoader();
                                    if (email.length > 0 && password.length > 0) {
                                        this.props.userSignIn({ email, password });
                                    }

                                }}
 
                                variant="raised" color="primary" disabled={btnDisabled}>
                                    <IntlMessages id="appModule.signIn" />

                                </Button>
                            </Form>
                        </div>
                    </div>

                </div>
                {
                    loader &&
                    <div className="loader-view">
                        <CircularProgress />
                    </div>
                }
                {showMessage && NotificationManager.error(alertMessage)}
                <NotificationContainer />
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    const { loader, alertMessage, showMessage, authUser } = auth;
    return { loader, alertMessage, showMessage, authUser }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ userSignIn: userSignIn, hideMessage: hideMessage, showAuthLoader: showAuthLoader }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
