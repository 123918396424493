import {
    GET_CONDITIONS,
    EDIT_CONDITION,
    SET_CONDITIONS,
    ADD_CONDITION,
    DD_ADD_CONDITION,
    SAVED_QUOTE,
} from 'constants/ActionTypes';

const INIT_STATE = {
    allConditions: [],
    editCondition: null,
    savedQuote: false,
};

const conditions = (state = INIT_STATE, action) => {
    switch(action.type) {
        case GET_CONDITIONS: {
            return {
                ...state,
                allConditions: action.payload,
            }
        }

        case EDIT_CONDITION: {
            return {
                ...state,
                editCondition: action.payload,
                savedSuccessfully: false
            }
        }

        case SET_CONDITIONS: {
          return {
              ...state,
              allConditions: action.payload
          }
        }

        case SAVED_QUOTE: {
            return {
                ...state,
                savedQuote: action.payload
            }
        }

        default:
        return state;
    }
}

export default conditions;