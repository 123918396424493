import {
    GET_ENDUSERS,
    ENDUSER_NAMES,
    SELECT_ENDUSER,
    END_USER_EDIT,
    END_USER_EDIT_LIST,
} from 'constants/ActionTypes';

const INIT_STATE = {
    allEndUsers:  null,
    allEndUserNames: [' '],
    selectedEndUser: null,
    editEndUser: {},
    endUserEditList: {}
};

const endusers = (state = INIT_STATE, action) => {
    switch(action.type) {
        case GET_ENDUSERS: {
            return {
                ...state,
                allEndUsers: action.payload
            }
        }

        case  END_USER_EDIT: {
            return {
                ...state, 
                editEndUser: action.payload
            }
        }

        case ENDUSER_NAMES: {
            return {
                ...state,
                allEndUserNames: action.payload,
            }
        }

        case SELECT_ENDUSER: {
            return {
                ...state,
                selectedEndUser: action.payload,
            }
        }

        case END_USER_EDIT_LIST: {
            return {
                ...state,
                endUserEditList: action.payload,
            }
        }

        default:
        return state;
    }
}

export default endusers;