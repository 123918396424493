
import config from '../config/config';

import React,{createContext, useState, useContext} from 'react';
import { useSelector } from 'react-redux';
import buildNameArray, { renderField } from '../util/QuoteFormUtil';
import { NotificationManager } from 'react-notifications';
import { Input, Label } from 'reactstrap';
import { authFetchHook } from './AuthenticatedFetch';
import { times } from 'lodash';
import quoteTemplate from '../components/QuotePDF/quoteTemplate';

const createQuoteTemplate = { 
    startDate: null,
    showCustomerModal: false,
    showCustomerNewModal: false,
    showContactEditModal: false,
    showContactNewModal: false,
    showEndUserEditModal: false,
    showEndUserNewModal: false,
    showJobSiteEditModal: false,
    showJobSiteNewModal: false,
    selectedEndUser: null,
    selectedCustomer: null,
    selectedJobSite: null,
    selectedContact: null,
    showSpinner: false,
    currentQuote: null,
    doRedirect: false,
    sortBy: null,
    quoteId: 0,
    sortDirection: null,
    lastId: 0,
    isSearching: false,
    quotes: null,
    bottomId: 0,
    topId: 0,
    searchTerm: null,
    searchText: null,
    searchBox: false,
    customerNames: [],
    customers: null,
    contacts: null,
    contactNames: [],
    jobSites: null,
    jobSiteNames: [],
    endUserNames: [],
    endUserData: null,
    editCustomer: null,
    editContact: null,
    editEndUser: null,
    editJobSite: null,
    endUsers: null,
    headerRedirect: false, 
    spinner: false,
    auditLogs: null,
    conditions: null,
    summaryQuote: null,
    activeTab: '1',
    setDoRedirect: ()=>{},
    setSortBy: () => {},
    setQuoteId: () => {},
    setSortDirection:()=>{},
    setLastId:() => {},
    setStartDate: ()=>{},
    setShowCustomerModal: ()=>{},
    setShowCustomerNewModal: ()=>{},
    setShowContactEditModal: ()=>{},
    setShowContactNewModal: ()=>{},
    setShowEndUserEditModal: ()=>{},
    setShowEndUserNewModal: ()=>{},
    setShowJobSiteEditModal: ()=>{},
    setShowJobSiteNewModal: ()=>{},
    setSelectedEndUser: ()=>{},
    setSelectedCustomer: ()=>{},
    setSelectedJobSite: ()=>{},
    setShowSpinner: ()=>{},
    setCurrentQuote: () => {},
    newQuote: async() => {},
    prevPage: () => {},
    nextPage: () => {},
    setIsSearching: () => {},
    setQuotes: () => {},
    setBottomId: () => {},
    setTopId: () => {},
    setSearchTerm:() => {},
    setSearchText:() => {},
    setSearchBox:() => {},
    searchQuotes: async () => {},
    getQuote: async () => {},
    sortCurrentQuotes: () => {},
    setCustomerNames: () => {},
    setCustomers: () => {},
    getCustomers: async () => {},
    getContacts:  async () => {},
    getJobSites: async () => {},
    getEndUsers:  async () => {},
    setEditCustomer: () => {},
    setEditContact: () => {},
    setEditEndUser: () => {},
    setEditJobSite: () => {},
    setSelectedContact: () => {},
    renderFld: () => {},
    renderSelFld: () => {},
    setHeaderRedirect: () =>{},
    toggleSpinner: () => {},
    saveQuote: async () => {},
    addLog: async () => {},
    getLog: async () => {},
    setAuditLogs:() =>{},
    saveNewCustomer:async () => {},
    renderField:() => {},
    saveEditCustomer:async () => {},
    deleteCustomer: async () => {},
    saveContact: async () => {},
    getCustomerContacts: async () => {},
    saveEditContact: async () => {},
    saveNewEndUser: async () => {},
    saveEditEndUser: async () => {},
    saveNewJobSite: async () => {},
    saveEditJobSite: async () => {},
    getConditions: async() => {},
    saveConditions: async() => {},
    createCondition: async() =>{},
    updateCondition : async() =>{},
    saveQuoteItems : async () => {},
    setSummaryQuote: () =>{},
    setActiveTab: () => {},
    RenderPDF: () => {},
    clearQuote: () => {}
    
}

export const CreateQuoteContext = createContext(createQuoteTemplate);

export const useCreateQuoteContext = () => useContext(CreateQuoteContext)


export const CreateQuoteProvider = ({children}) => {

    

   const [startDate,setStartDate] = useState(null) 
   const [showCustomerModal, setShowCustomerModal] = useState(false)
   const [showCustomerNewModal, setShowCustomerNewModal] = useState(false)
   const [showContactEditModal, setShowContactEditModal] = useState(false)
   const [showContactNewModal, setShowContactNewModal] = useState(false)
   const [showEndUserEditModal, setShowEndUserEditModal] = useState(false)
   const [showEndUserNewModal, setShowEndUserNewModal] = useState(false)
   const [showJobSiteEditModal, setShowJobSiteEditModal] = useState(false)
   const [showJobSiteNewModal, setShowJobSiteNewModal] = useState(false)  
   const [selectedEndUser, setSelectedEndUser] = useState(null)
   const [selectedCustomer, setSelectedCustomer] = useState(null)
   const [selectedJobSite, setSelectedJobSite] = useState(null)
   const [selectedContact, setSelectedContact] = useState(null)
   const [showSpinner, setShowSpinner] = useState(false)
   const [currentQuote, setCurrentQuote] = useState(null)
   const [doRedirect, setDoRedirect] = useState(false)
   const [quoteId, setQuoteId] = useState(0)
   const [sortBy,setSortBy] = useState(null)
   const [sortDirection, setSortDirection] = useState(null)
   const [lastId,setLastId] = useState(null)
   const [isSearching, setIsSearching] = useState(false)
   const [quotes, setQuotes] = useState([])
   const [bottomId,setBottomId] = useState(0)
   const [topId, setTopId] = useState(0)
   const [searchTerm, setSearchTerm] = useState(null)
   const [searchText, setSearchText] = useState(null)
   const [searchBox,setSearchBox] = useState(false)
   const [customers, setCustomers] = useState(null)
   const [customerNames, setCustomerNames] = useState([])
   const [contacts, setContacts] = useState(null)
   const [contactNames, setContactNames] = useState([])
   const [jobSites, setJobSites] = useState(null)
   const [jobSiteNames, setJobSiteNames] = useState([])
   const [endUsers, setEndUsers] = useState(null)
   const [endUserNames, setEndUserNames] = useState([]) 
   const [endUserData, setEndUserData] = useState(null)
   const [editCustomer, setEditCustomer] = useState(null)
   const [editContact, setEditContact] = useState(null)
   const [editEndUser,setEditEndUser] = useState(null)
   const [editJobSite,setEditJobSite] = useState(null)
   const [headerRedirect, setHeaderRedirect] = useState(false)
   const [spinner,setSpinner] = useState(false)
   const [auditLogs,setAuditLogs] = useState(null)   
   const [conditions,setConditions] = useState(null)
   const [summaryQuote,setSummaryQuote] = useState(null)
   const [activeTab, setActiveTab] = useState('1')
  
   const user = useSelector(state => state.auth)


   const toggleSpinner = () => {
    setSpinner(!spinner)
   }

   const getConditions = async () => {
    let cond = await authFetchHook(user.authUser, `${config.apiPath}/conditions/`, "GET")
    setConditions(cond)
   }

   const saveConditions = async (condins) => {

      let editQt = currentQuote
      setShowSpinner(true)
      editQt.Conditions = condins.filter(cn => cn.isActive)
      await authFetchHook(user.authUser, `${config.apiPath}/conditions/assign/${editQt.id}`, "PUT", JSON.stringify(editQt))
      setShowSpinner(false)
      NotificationManager.success("Conditions Saved", "Success", 2000);
      getQuote(currentQuote.id)

   }

   const RenderPDF = (quote) => {
    setSummaryQuote(quote)
    setActiveTab('5')
   }

   const createCondition = async (cond) => {
      setShowSpinner(true)
      await authFetchHook(user.authUser,`${config.apiPath}/conditions/`, "POST", JSON.stringify(cond))
      setShowSpinner(false)
      NotificationManager.success("Condition Created", "Success", 2000);
      getConditions()

   }

   const updateCondition = async (condition) => {
    setShowSpinner(true)
    await authFetchHook(user.authUser, `${config.apiPath}/conditions/${condition.id}`, "PUT", JSON.stringify(condition))
    setShowSpinner(false)
    NotificationManager.success("Condition Updated", "Success", 2000);
    getConditions()
   }

   const searchQuotes = async (search) => {

    if (!search) {
        let quotes = await authFetchHook(user.authUser, `${config.apiPath}/quotes/list`, "GET")
        setIsSearching(false)
        console.log('setting quotes to', quotes)
        setQuotes(quotes)
        setTopId(quotes[quotes.length -1].id)
        setBottomId(quotes[0].id)
      } else {
        let body = JSON.stringify({ searchTerm: search });
        let quotes = await authFetchHook(user.authUser, `${config.apiPath}/quotes/search`, "POST", body)
       
        if(quotes && quotes.length > 0) {
          setIsSearching(true)
          setQuotes(quotes)
          setTopId(quotes[quotes.length -1].id)
          setBottomId(quotes[0].id)
        }
      }
   }

   const saveNewCustomer = async (customer) => {
      setShowSpinner(true)
      let body = JSON.stringify(customer);
     
      let cust = await authFetchHook(user.authUser,`${config.apiPath}/customers/`, "POST", body)
      //setSelectedCustomer(cust)
      setShowSpinner(false)
       
      NotificationManager.success('Customer Saved','Success', 2000);
   }

   const saveEditCustomer = async (customer) =>  {
      let body = JSON.stringify(customer);
      setShowSpinner(true)
      await authFetchHook(user.authUser, `${config.apiPath}/customers/${customer.id}`, "PUT", body )
      setShowSpinner(false)
      NotificationManager.success('Customer Edited','Success', 2000);
   }

   const deleteCustomer = async (customer) => {
      setShowSpinner(true)
      console.log('deleting ', customer)
      await authFetchHook(user.authUser, `${config.apiPath}/customers/${customer.id}`, "DELETE" )
      setShowSpinner(false)
      NotificationManager.success('Customer Deleted','Success', 2000);
   }

   const saveContact =  async (contact) => {
    let body = JSON.stringify(contact);
    setShowSpinner(true)
     await authFetchHook(user.authUser, `${config.apiPath}/contacts/`, "POST", body)
     setShowSpinner(false)
     NotificationManager.success("Contact Saved", "Success", 2000);

     
   }

   const saveEditContact = async (contact) => {
    let body = JSON.stringify(contact);
    setShowSpinner(true)
    await authFetchHook(user.authUser, `${config.apiPath}/contacts/${contact.id}`, "PUT", body)
    setSelectedContact(contact)
    NotificationManager.success("Contact Updated", "Success", 2000);
    setShowSpinner(false)
   }

   const getCustomerContacts = async (cust,name) => {
    let cntacts = await authFetchHook(user.authUser,`${config.apiPath}/contacts/customer/${cust.id}`, "GET")
    console.log('setting contacts too', cntacts)
    setContacts(cntacts)
    let names = buildNameArray(cntacts)
    setContactNames(names)

    if(name) {
      cntacts.forEach( cn =>{
        if(cn.name === name) {
          setSelectedContact(cn)
        }
      })
    }
  }

  const saveNewJobSite = async (jobSite) => {
    setShowSpinner(true)
    await authFetchHook(user.authUser,  `${config.apiPath}/jobsites/`, "POST", JSON.stringify(jobSite) )
    NotificationManager.success('JobSite Saved', 'Success', 2000);
    setShowSpinner(false)

  }

  const saveEditJobSite = async (jobSite) => {
    setShowSpinner(true)
    await authFetchHook(user.authUser, `${config.apiPath}/jobsites/${jobSite.id}`, "PUT", JSON.stringify(jobSite))
    setSelectedJobSite(jobSite)
    NotificationManager.success('JobSite Saved', 'Success', 2000);
    setShowSpinner(false)
  }

  const saveQuoteItems = async (qte) => {
    setShowSpinner(true)
    qte.QuoteItems.map(itm => {
      if(!itm.quantity || itm.quantity === ' ') {
        itm.quantity = 0
      }
      if(!itm.hour) {
        itm.hrsPerUnit = null
      }
      if(!itm.dollarsPerUnit || itm.dollarsPerUnit === ' ') {
        itm.dollarsPerUnit =  0
      }
      if(!itm.totalHours) {
        itm.totalHours = null
      }
      if(!itm.standardTime) {
        itm.standardTime = null
      }
      if(!itm.overTime) {
        itm.overTime = null
      }
      if(!itm.doubleTime) {
        itm.doubleTime = null
      }
      if(!itm.cost || itm.cost === ' ') {
        itm.cost = 0
      }
    })
    if(!qte.costTotaled) {
      qte.costTotaled = 0
    }

    console.log('saving ', qte)
    await authFetchHook(user.authUser, `${config.apiPath}/quotes/${qte.id}`,
    "PUT",
    JSON.stringify(qte))
    setShowSpinner(false)
    NotificationManager.success("Quote Items Saved", "Success", 2000);
    await getQuote(qte.id)
        
  
  }

   const saveQuote = async (quote) => {
    setShowSpinner(true)
    await authFetchHook(user.authUser,  `${config.apiPath}/quotes/`, "POST", JSON.stringify(quote))
    setShowSpinner(false)
    await getQuote(quote.id)
    NotificationManager.success("Quote Saved", "Success", 2000);
   }

   const addLog = async (lg,qid) => {

    let log = {
        user: user.authUser.id,
        name: user.authUser.firstName + ' ' + user.authUser.lastName,
        action: lg,
        QuoteId: qid ? qid : currentQuote.id
    }
     await authFetchHook(user.authUser, `${config.apiPath}/auditlogs`, "POST", JSON.stringify(log))    
    getLog(currentQuote.id)
   } 

   const getLog = async (quoteId) => {
       setAuditLogs(null)
        let logs = await authFetchHook(user.authUser, `${config.apiPath}/auditlogs/${quoteId}`, "GET")
       
        setAuditLogs(logs)
        console.log('LOGGIES ARE ', auditLogs) 
            
   }

   const getQuote = async (quoteId) => {
    let qte = await authFetchHook(user.authUser, `${config.apiPath}/quotes/${quoteId}`, "GET")
    setCurrentQuote(qte)
    setSummaryQuote(qte)
    qte.QuoteItems.map( itm => {
      if(!itm.cost) {
        itm.cost = ' ' 
      }
      if(!itm.quantity) {
        itm.quantity = 0
      }
      if(!itm.dollarsPerUnit) {
        itm.dollarsPerUnit = 0
      }
      if(!itm.description) {
        itm.description = 0
      }

    })
    if(!qte.costTotaled) {
      qte.costTotaled = 0
    }
    if (qte.CustomerId) {
      setEditCustomer(qte.Customer)
      setSelectedCustomer(qte.Customer)

      getCustomerContacts(qte.Customer)
     }
     if (qte.CustomerContact) {
       setEditContact(qte.CustomerContact)
       setSelectedContact(qte.CustomerContact)
     }

     if (qte.EndUser) {
       setEditEndUser(qte.EndUser)
       setSelectedEndUser(qte.EndUser)
     }

     if (qte.JobSite) {
       setEditJobSite(qte.JobSite)
       setSelectedJobSite(qte.JobSite)
     }
   }

   const prevPage = async (isSearch) => {

    if (!isSearch) {
        let quotes = await authFetchHook(user.authUser, `${config.apiPath}/quotes/listprev/` +topId, "GET")
        setQuotes(quotes)
        setTopId(quotes[quotes.length -1].id)
        setBottomId(quotes[0].id)
      } else {
        let body = JSON.stringify({ searchTerm: searchTerm });
        let quotes = await authFetchHook(user.authUser, `${config.apiPath}/quotes/searchprev/` + topId, "POST", body)
        if(quotes && quotes.length > 0) {
          setQuotes(quotes)
          setTopId(quotes[quotes.length -1].id)
          setBottomId(quotes[0].id)
        }
        
      }
   }

   const getContacts = async() => { 
    
      let contacts = await authFetchHook(user.authUser, `${config.apiPath}/contacts/list`, "GET")
      setContactNames(buildNameArray(contacts))
      setContacts(contacts)
   }

   const getJobSites = async(jobSite) => {
    let sites = await authFetchHook(user.authUser, `${config.apiPath}/jobsites/list`, "GET")
    if(jobSite) {
      sites.forEach(st => {
        if(st.address === jobSite.address) {
          setSelectedJobSite(st)
        }
      })
    }
    setJobSites(sites)
    setJobSiteNames(buildNameArray(sites))

   }

   const sortCurrentQuotes = () => {

    switch (sortBy) {
        case 'JobSiteAddress': {
          if (sortDirection === 'ASC') {
            quotes.sort((a, b) => {
    
              if (a["JobSite"].address > b["JobSite"].address) {
                return 1
              }
    
              if (a["JobSite"].address < b["JobSite"].address) {
                return -1
              }   
            })
          } else {
            quotes.sort((a, b) => {
    
              if (a["JobSite"].address < b["JobSite"].address) {
                return 1
              }
    
              if (a["JobSite"].address > b["JobSite"].address) {
                return -1
              }
    
            })
          }
          break;
        }
        case 'SalesPerson': {
          if (sortDirection === 'ASC') {
            quotes.sort((a, b) => {
    
              if (a[sortBy].firstName > b[sortBy].firstName) {
                return 1
              }
    
              if (a[sortBy].firstName < b[sortBy].firstName) {
                return -1
              }
    
            })
    
          } else {
            quotes.sort((a, b) => {
    
              if (a[sortBy].firstName < b[sortBy].firstName) {
                return 1
              }
    
              if (a[sortBy].firstName > b[sortBy].firstName) {
                return -1
              }
    
            })
          }
          break;
        }
        default: {
          if (sortDirection === 'ASC') {
            quotes.sort((a, b) => {
    
              if (a[sortBy] > b[sortBy]) {
                return 1
              }
    
              if (a[sortBy] < b[sortBy]) {
                return -1
              }
    
            })
    
          } else {
            quotes.sort((a, b) => {
    
              if (a[sortBy] < b[sortBy]) {
                return 1
              }
    
              if (a[sortBy] > b[sortBy]) {
                return -1
              }
    
            })
          }
        }
   }
   setQuotes(quotes)
}

const getCustomers = async (name, editFunc) => {
    let customers = await authFetchHook(user.authUser, `${config.apiPath}/customers/list`, "GET")
    if(name) {
      customers.forEach(
        cust => {
          if(cust.name === name) {
            setSelectedCustomer(cust)
          }
        }
      )
    }
    setCustomerNames(buildNameArray(customers))

            if(editFunc) {
                customers = customers.map(cust => {return cust["edit"] = <Edit onClick={(e) => {editFunc(cust)}}/>})
            }
           
            let customerData = {
              columns: [
                  {
                      label: '',
                      field: 'edit',
                      sort: 'asc',
                      width: 150
                  },
                  {
                      label: 'ID',
                      field: 'id',
                      sort: 'asc',
                      width: 150
                  },
                  {
                      label: 'Name',
                      field: 'name',
                      sort: 'asc',
                      width: 150
                  },
                  {
                      label: 'Bill To',
                      field: 'billTo',
                      sort: 'asc',
                      width: 150
                  },
                  {
                      label: 'Address',
                      field: 'address',
                      sort: 'asc',
                      width: 150
                  },
                  {
                      label: 'Address 2',
                      field: 'address2',
                      sort: 'asc',
                      width: 300
                  },
                  {
                      label: 'Address 3',
                      field: 'address3',
                      sort: 'asc',
                      width: 300
                  },
                  {
                      label: 'City',
                      field: 'city',
                      sort: 'asc',
                      width: 300
                  },
                  {
                      label: 'State',
                      field: 'state',
                      sort: 'asc',
                      width: 300
                  },
                  {
                      label: 'Zip Code',
                      field: 'zip',
                      sort: 'asc',
                      width: 300
                  }
              ],
              rows: customers
          }
          setCustomers(customerData)
}


const getEndUsers = async (eUser, editFunc) => {

   let eUsers =  await authFetchHook(user.authUser, `${config.apiPath}/endusers/list`, "GET")
    if(eUser) {
      eUsers.forEach( eu => {
        if(eu.name == eUser.name) {
          setSelectedEndUser(eu)
        }
      })

    }

    if(editFunc) {
        eUsers = eUsers.map(eu => {return eu["edit"]  = <Edit onClick={(e) => {editFunc(eu)}} />})
    }
    
    setEndUserNames(buildNameArray(eUsers))
    setEndUsers(eUsers)
            
            const endUserData = {
                columns: [
                    {
                        label: '',
                        field: 'edit',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'ID',
                        field: 'id',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Name',
                        field: 'name',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Address',
                        field: 'address',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Contact',
                        field: 'contact',
                        sort: 'asc',
                        width: 150
                    },
                
                ],
                rows: eUsers
            }
            setEndUserData(endUserData)
        
}

const saveNewEndUser = async (endUser) => {
  setShowSpinner(true)
  await authFetchHook(user.authUser, `${config.apiPath}/endusers`,
  "POST",
  JSON.stringify(endUser))
  setShowSpinner(false) 
  NotificationManager.success('End User Saved','Success', 2000);
}

const saveEditEndUser = async (endUser) => {
  setShowSpinner(true)
  await authFetchHook(user.authUser,`${config.apiPath}/endusers`,
  "PUT",
  JSON.stringify(endUser) )
  setShowSpinner(false)
  setSelectedEndUser(endUser)
  
  NotificationManager.success('End User Saved','Success', 2000)
}

   const nextPage = async (isSearch) => {
    if (!isSearch) {
      let quotes  = await authFetchHook(user.authUser, `${config.apiPath}/quotes/listnext/` + bottomId, "GET")
      setQuotes(quotes)
      setTopId(quotes[quotes.length -1].id)
      setBottomId(quotes[0].id)
    } else {
      let body = JSON.stringify({ searchTerm: searchTerm });
      let quotes  = await authFetchHook(user.authUser, `${config.apiPath}/quotes/searchnext/` + bottomId, "POST", body)
      if(quotes && quotes.length > 0) {
        setQuotes(quotes)
        setTopId(quotes[quotes.length -1].id)
        setBottomId(quotes[0].id)
      }
    }
   }

   const renderField = (label,control,setter,type,error,errorSetter) => (
    <div style={type === 'checkbox' ? { marginLeft: '30px', marginTop: '10px', marginBottom: '10px' } : {}}>
        {type !== 'checkbox'  && <label>{label}</label> }
        <div style={type === 'checkbox' ? { float: 'left' } : {}}>
            {type !== 'checkbox' && <Input type={type} id='name' name='name' value={control} onChange={(e) => {setter(e.target.value);errorSetter(null)}} />}
            {type === 'checkbox' &&  <Label check><Input type={type} id='name' name='name' checked={control} onChange={(e) => {setter(e.target.value);errorSetter(null)}} />{label}</Label>}
            {error && <span style={{ color: 'red' }}>{error}</span>}
        </div>
    </div>
)

   const renderFld = (input, type,value,label,isReadOnly, error) =>  (<div>
              <div>
                <label>{label}</label>
              </div>
              {type === 'checkbox' && 
                <Input {...input} checked={value} placeholder={label} type={type}  autoComplete='off' readOnly= {isReadOnly}/> 
              }
              {type !== 'checkbox' && <Input {...input} value={value} placeholder={label} type={type}  autoComplete='off' /> }
              {error  && <span style={{ color: 'red' }}>{error}</span>}
            </div>)
   

   const renderSelFld = (input, value, label, children, hndlr, error) => 
    (
      <div>
        <label>{label}</label>
      <div>
      <select {...input} value={value} className="form-control" onChange={hndlr}>
        {children.map(element => 
           <option>{element}</option>
        )}
      </select>
      {error  && <span style={{ marginLeft: '10px', color: 'red' }}>{error}</span>}
    </div>
  </div>
      
    )

    const convertRate = (rate) => {
      let orate = {};
      orate.description = rate.type;
      orate.standardTime = rate.standardRate
      orate.overTime = rate.overTimeRate;
      orate.doubleTime = rate.doubleTimeRate;
      return orate;
    }
   
  const clearQuote = () => {
     setSelectedContact(null)
     setSelectedCustomer(null)
     setSelectedEndUser(null)
     setSelectedJobSite(null)
     setContactNames(null)
     setAuditLogs(null)
     setConditions(null)
  }


   const newQuote = async () => {

    let rateMain = await authFetchHook(user.authUser, `${config.apiPath}/rates/main`, "GET").then( json => {return json})
    
    let quote = {
      status: "Quote",
      date: new Date().toDateString(),
      SalesPersonUserId: user.authUser.id,
      jobTotal: 0,
      discount: rateMain.discount,
      receiveDeliver: rateMain.receiveDeliver,
      otPremUnload: rateMain.otPremUnload,
      Rates: []
    };

    rateMain.MasterRates.map(rate => {
      quote.Rates.push(convertRate(rate));
    });

    let body = JSON.stringify(quote);
    let squote = await authFetchHook(user.authUser, `${config.apiPath}/quotes/`, "POST", body)
    console.log('squote is', squote)
    let newItem = {
          itemOrder:1,
          rateType:'Installers',
          quantity:'0',    
          dollarsPerUnit:'0',
          cost:'0'
     }
     squote.costTotaled = 0
        
     squote.QuoteItems = [];
     squote.QuoteItems.push(newItem);
     setSelectedContact(null)
     setSelectedCustomer(null)
     setSelectedEndUser(null)
     setSelectedJobSite(null)
     setAuditLogs(null)
     setConditions(null)
     await authFetchHook(user.authUser,`${config.apiPath}/quotes/${squote.id}`,
      "PUT",
      JSON.stringify(squote) )

        setCurrentQuote(squote)
         // dispatch({ type: SUMMARY_QUOTE, payload: response.data });  
        addLog('created Quote', squote.id)
        window.location = `/#/app/quote?quoteId=${squote.id}`

   }

   return (<CreateQuoteContext.Provider value={{ 
    startDate,
    showCustomerModal,
    showCustomerNewModal,
    showContactEditModal,
    showContactNewModal,
    showEndUserEditModal,
    showEndUserNewModal,
    showJobSiteEditModal,
    showJobSiteNewModal,
    selectedEndUser,
    selectedCustomer,
    selectedJobSite,
    showSpinner,
    currentQuote,
    setCurrentQuote,
    setStartDate,
    setShowCustomerModal,
    setShowCustomerNewModal,
    setShowContactEditModal,
    setShowContactNewModal,
    setShowEndUserEditModal,
    setShowEndUserNewModal,
    setShowJobSiteEditModal,
    setShowJobSiteNewModal,
    setSelectedEndUser,
    setSelectedCustomer,
    setSelectedJobSite,
    setShowSpinner,
    newQuote,
    doRedirect,
    sortBy,
    quoteId,
    sortDirection,
    lastId,
    setDoRedirect,
    setSortBy,
    setQuoteId,
    setSortDirection,
    setLastId,
    prevPage,
    nextPage,
    isSearching,
    setIsSearching,
    topId,
    setTopId,
    bottomId, 
    setBottomId,
    quotes,
    setQuotes,
    searchTerm,
    setSearchTerm,
    searchText,
    setSearchText,
    searchBox,
    setSearchBox,
    searchQuotes,
    getQuote,
    sortCurrentQuotes,
    getCustomers,
    setCustomerNames,
    customers,
    customerNames,
    setCustomers,
    contactNames,
    contacts,
    getContacts,
    jobSites,
    jobSiteNames,
    getJobSites,
    getEndUsers,
    endUserData,
    endUserNames,
    endUsers,
    editCustomer,
    setEditCustomer,
    editContact,
    setEditContact,
    editEndUser,
    setEditEndUser,
    editJobSite,
    setEditJobSite,
    selectedContact,
    setSelectedContact,
    renderFld,
    renderSelFld,
    headerRedirect,
    setHeaderRedirect,
    spinner,
    toggleSpinner,
    saveQuote,
    addLog,
    auditLogs,
    getLog,
    setAuditLogs,
    saveNewCustomer,
    renderField,
    saveEditCustomer,
    deleteCustomer,
    saveContact,
    getCustomerContacts,
    saveEditContact,
    saveNewEndUser,
    saveEditEndUser,
    saveNewJobSite,
    saveEditJobSite,
    conditions,
    getConditions,
    saveConditions,
    createCondition,
    updateCondition,
    saveQuoteItems,
    summaryQuote,
    setSummaryQuote,
    activeTab,
    setActiveTab,
    RenderPDF,
    clearQuote
    }}>
        {children}
    </CreateQuoteContext.Provider>)
}