import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { editUserById, updateUser, createUser, updateUserForm, clearUserForm, clearSuccessFlag } from '../../actions/Users';
import { Field, reduxForm } from 'redux-form';
import {
    Button,
    Container,
    Col,
    Row,
    Label,
    FormGroup,
    Input
  } from 'reactstrap';  


  const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div style={type === 'checkbox' ? { marginLeft: '10px' } : {}}>
      <label>{label}</label>
      <div style={type === 'checkbox' ? { float: 'left' } : {}}>
        <Input {...input} placeholder={label} type={type} autoComplete='off' />
        {touched && error && <span style={{color: 'red'}}>{error}</span>}
      </div>
    </div>
  )

  const renderSelectField = ({ input, label, type, meta: { touched, error }, children }) => (
    <div>
      <label>{label}</label>
      <div>
        <select {...input} className="form-control">
          {children}
        </select>
        {touched && error && <span style={{ marginLeft: '10px', color: 'red'}}>{error}</span>}
      </div>
    </div>
  );

  const validate = values => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = 'Required'
    }
    if (!values.lastName) {
        errors.lastName = 'Required'
      }
    if (!values.email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }
    if (!values.password) {
      errors.password = 'Required'
    } else if (values.password.length < 6) {
      errors.password = 'Password must be at least 6 characters long!'
    }

    if (!values.role || values.role == 'select role') {
        errors.role = "Please select a role"
    }

    return errors
  }
  

  class UserCrud extends React.Component {
    
    constructor(props){
        super(props);
    }

    static propTypes = {
        onClose: PropTypes.func,
        editUserId: PropTypes.number
    };

    componentWillMount() {
        if(this.props.editUserId > 0) {
            this.props.editUserById(this.props.editUserId);
        }
    }

    componentWillReceiveProps() {
        if (this.props.savedSuccessfully) {
            setTimeout(this.props.clearSuccessFlag, 200);   
        }
    }

    createOrUpdateUser = (user) => {
        if (user.hasOwnProperty("id")) {
            this.props.updateUser(user);
        } else {
            this.props.createUser(user);
        }
        
        this.close();
    }

    close() {
        if (this.props.onClose && this.props.onClose instanceof Function) {
            this.props.onClose();
          }
    }

    render () {
        const { pristine, submitting, user } = this.props;
        const savedSuccessfully = this.props.savedSuccessfully;
        
        return ( 
        <div className="app-wrapper">
        <Container fluid={true}>
        {/* {savedSuccessfully ? NotificationManager.success('User Updated','Success', 2000) : null} */}
          <form onSubmit={this.props.handleSubmit(this.createOrUpdateUser)}>
              <FormGroup>
            <Row>
                <Col>
                    <Field name="firstName" type="text" component={renderField} label="First Name:" />
                </Col>
            </Row> 
            <Row>
                <Col>
                    <Field name="lastName" type="text" component={renderField} label="Last Name:" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field name="email" type="text" component={renderField} label="Email:" />
                </Col>
            </Row>
           
                <Row>
                    <Col>
                        <Field name="npassword" type="password" component={renderField} label="Password:" />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
            <Row style={{marginLeft:9}}>
                <Col>
                    <Field name="active" type="checkbox" component={renderField} label="Active" />
                </Col>
            </Row>
            </FormGroup>
            <FormGroup>
            <Row>
                <Col>
                <Field name="role" component={renderSelectField} label="Role:">
                    <option>select role</option>
                    <option>admin</option>
                    <option>manager</option>
                    <option>user</option>
                    <option>dispatch</option>
                </Field>
                </Col>
            </Row>
            </FormGroup>
            <FormGroup>
            <Row>
                <Col>
                  <Label>Last Login: </Label>
                </Col>
            </Row>
            
            <Row>
                <Col>      
                    <Label>{user.lastLoggedIn}</Label>
                </Col>
            </Row>
            </FormGroup>
            <FormGroup>
            <Row>
                <Col>
                  <Label>Failed Attempts: </Label>
                </Col>
            </Row>
            <Row>
                <Col>      
                  <Label>{user.failedLoginCount}</Label>
                </Col>
            </Row>
            </FormGroup>
            <FormGroup>
            <Row>
                <Col >
                    <Button color="danger" onClick={(e) => this.close()} className="jr-btn float-left">Cancel</Button>
                    <Button type="submit" color="primary" className="jr-btn float-right" disabled={pristine || submitting} >Save</Button>
                </Col>
              </Row>
              </FormGroup>
          </form>
        </Container>
        </div>
      );

    } 

  }

const mapStateToProps = (state) => {
    return {
        user: state.users.editUser,
        savedSuccessfully: state.users.savedSuccessfully,
        initialValues: state.users.editUser,
        userForm: state.form.userCrud
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        editUserById: editUserById,
        createUser: createUser,
        updateUser: updateUser,
        updateUserForm: updateUserForm,
        clearUserForm: clearUserForm,
        clearSuccessFlag: clearSuccessFlag
    }, dispatch);
}

UserCrud = reduxForm({
    form: 'userCrud',  // a unique identifier for this form
    enableReinitialize: true,
    validate
  })(UserCrud)
  

UserCrud = connect(mapStateToProps, mapDispatchToProps)(UserCrud);

export default UserCrud;