import React from 'react';
import {Avatar} from '@mui/material'
import {connect} from 'react-redux'
import {Menu} from '@mui/material';
import {MenuItem}
from '@mui/material';
import {userSignOut} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';

class UserInfo extends React.Component {
    
    
    constructor() {
       super();
        this.state = {
            anchorEl: null,
            open: false,
        };
    }
    

    handleClick = event => {
        this.setState({open: true, anchorEl: event.currentTarget});
    };

    handleRequestClose = () => {
        this.setState({open: false});
    };

    render() {
        const {authUser} = this.props;
        return (
            <div className="user-profile d-flex flex-row align-items-center">
                <Avatar
                    alt='...'
                    src='http://via.placeholder.com/150x150'
                    className="user-avatar "
                />
                <div className="user-detail">
                    <h4 className="user-name" onClick={this.handleClick}>{ authUser.firstName} {authUser.lastName} <i
                        className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
                    </h4>
                </div>
                <Menu className="user-info"
                      id="simple-menu"
                      anchorEl={this.state.anchorEl}
                      open={this.state.open}
                      onClose={this.handleRequestClose}
                      PaperProps={{
                          style: {
                              width: 120,
                              paddingTop: 0,
                              paddingBottom: 0
                          }
                      }}
                >
                    <MenuItem onClick={() => {
                                      this.handleRequestClose();
                                      this.props.toggleNav();
                                       this.props.toggleUser();
                     } }>
                        <i className="zmdi zmdi-account zm  di-hc-fw mr-2"/>
                        <IntlMessages id="popup.profile"/>
                    </MenuItem>
                    <MenuItem onClick={() => {
                        this.handleRequestClose();
                        this.props.userSignOut()
                    }}>
                        <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>

                        <IntlMessages id="popup.logout"/>
                    </MenuItem>
                </Menu>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {locale,authUser} = state;
    return {locale, authUser: state.auth.authUser}
};

export default connect(mapStateToProps, {userSignOut})(UserInfo);


