import { ConnectingAirportsOutlined } from '@mui/icons-material';
import {
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_FACEBOOK_USER,
    SIGNIN_FACEBOOK_USER_SUCCESS,
    SIGNIN_GITHUB_USER,
    SIGNIN_GITHUB_USER_SUCCESS,
    SIGNIN_GOOGLE_USER,
    SIGNIN_GOOGLE_USER_SUCCESS,
    SIGNIN_TWITTER_USER,
    SIGNIN_TWITTER_USER_SUCCESS,
    SIGNIN_USER,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER,
    SIGNUP_USER_SUCCESS
} from 'constants/ActionTypes';
import config from '../config/config';

export const userSignUp = (user) => {
    return {
        type: SIGNUP_USER,
        payload: user
    };
};
export const userSignIn = (user) => {
    const loginCredentials = {
        "username": user.email,
        "password": user.password
    };
    return (dispatch) => {
        console.log('yepers') 
        fetch(`${config.apiPath}/users/login`, {
            "method": "POST",
            
            "headers": {
                "content-type": "application/json",

            },
            "body": JSON.stringify(loginCredentials)
        })
            .then(response => {
                return response.json().then(data => ({

                    data: data,
                    status: response.status
                }));
            })
            .then(response => {
                console.log('response is', response)
                if (response.status === 200) {
                    sessionStorage.setItem('user', JSON.stringify(response.data));
                  
                    dispatch({ type: SIGNIN_USER_SUCCESS, payload: response.data });


                } else {
                    var flash = {
                        type: 'error',
                        title: 'Error unable to login!',
                        content: response.data.reason
                    };

                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: 'Could not Login'
                    });

                    return false;
                }
            })
            .catch(err => { console.log(err); });
    };
};

export const userSignOut = () => {
    sessionStorage.removeItem('user');
    window.location = '/';
    return {
        type: SIGNOUT_USER
    };
};
export const userSignUpSuccess = (authUser) => {
    return {
        type: SIGNUP_USER_SUCCESS,
        payload: authUser
    };
};

export const userSignOutSuccess = () => {
    return {
        type: SIGNOUT_USER_SUCCESS,
    };
};


export const userGoogleSignIn = () => {
    return {
        type: SIGNIN_GOOGLE_USER
    };
};
export const userGoogleSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_GOOGLE_USER_SUCCESS,
        payload: authUser
    };
};
export const userFacebookSignIn = () => {
    return {
        type: SIGNIN_FACEBOOK_USER
    };
};
export const userFacebookSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_FACEBOOK_USER_SUCCESS,
        payload: authUser
    };
};
export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};
export const userTwitterSignIn = () => {
    return {
        type: SIGNIN_TWITTER_USER
    };
};
export const userTwitterSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_TWITTER_USER_SUCCESS,
        payload: authUser
    };
};
export const userGithubSignIn = () => {
    return {
        type: SIGNIN_GITHUB_USER
    };
};
export const userGithubSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_GITHUB_USER_SUCCESS,
        payload: authUser
    };
};
export const showAuthLoader = () => {
    return {
        type: ON_SHOW_LOADER,
    };
};

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE,
    };
};
export const hideAuthLoader = () => {
    return {
        type: ON_HIDE_LOADER,
    };
};
