import React, {Component} from 'react';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import MomentUtils from '@date-io/moment';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import 'styles/bootstrap.scss'
import 'styles/app.scss';
import 'styles/app-rtl.scss';
import AppLocale from '../lngProvider';
import darkTheme from './themes/darkTheme';
import MainApp from 'app/index';
import SignIn from './SignIn';
import {setInitUrl} from '../actions/Auth';
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';

const RestrictedRoute = ({component: Component, authUser, ...rest}) => {
    return(
        <Route
            {...rest}
            render={props =>
                (authUser !== null && authUser.hasOwnProperty('authenticated'))
                    ? <Component {...props} />
                    : <Redirect
                        to={{
                            pathname: '/signin',
                            state: {from: props.location}
                        }}
                    />}
        />
    );
}

class App extends Component {

    render() {
        const {match, location, locale, authUser, initURL, isDirectionRTL} = this.props;
        if (location.pathname === '/') {
            if (authUser === null) {
                return ( <Redirect to={'/signin'}/> );
            } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
                return ( <Redirect to={'/app/quotes-page'}/> );
            } else {
                return ( <Redirect to={initURL}/> );
            }
        }
        const applyTheme = createTheme(darkTheme);

        if (isDirectionRTL) {
            applyTheme.direction = 'rtl';
            document.body.classList.add('rtl')
        } else {
            document.body.classList.remove('rtl');
            applyTheme.direction = 'ltr';
        }

        const currentAppLocale = AppLocale[locale.locale];
        return (
            <ThemeProvider theme={applyTheme}>
               
                    <IntlProvider
                        locale={currentAppLocale.locale}
                        messages={currentAppLocale.messages}>
                       
                            <div className="app-main">
                                <Switch>
                                    <RestrictedRoute path={`${match.url}app`} authUser={authUser} component={MainApp}/>
                                    <Route path='/signin' component={SignIn}/>
                                    <Route component={asyncComponent(() => import('components/Error404'))}/>
                                </Switch>
                            </div>
                      
                    </IntlProvider>
             
            </ThemeProvider>
        );
    }
}

const mapStateToProps = ({settings, auth}) => {
    const {sideNavColor, locale, isDirectionRTL} = settings;
    const {authUser, initURL} = auth;
    return {sideNavColor, locale, isDirectionRTL, authUser, initURL}
};

export default connect(mapStateToProps, {setInitUrl})(App);

