import {
    GET_USERS,
    GET_USER_BY_ID,
    UPDATE_USER,
    CLEAR_USER_FORM
} from 'constants/ActionTypes';

const INIT_STATE = {
    siteUsers: [],
    editUser: {},
    savedSuccessfully: false
};

const users = (state = INIT_STATE, action) => {
    switch(action.type) {
        case GET_USERS: {
            return {
                ...state,
                siteUsers: action.payload
            }
        }

        case GET_USER_BY_ID: {
            return {
                ...state,
                editUser: action.payload,
                savedSuccessfully: false
            };
        }

        case UPDATE_USER: {
            return {
                ...state,
                savedSuccessfully: true
            }
        }
        
        case CLEAR_USER_FORM: {
            return {
                ...state,
                editUser: action.payload,
                savedSuccessfully: false
            }
        }

        default:
        return state;
    }
}

export default users;