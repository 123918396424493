import {
    SET_RATE,
    SET_MASTER_RATES,
    EDIT_RATE
} from 'constants/ActionTypes';

const INIT_STATE = {
    Rate: {  
        Rates: []     
    },
    MasterRates: [],
    editMasterRate: {}
};

const rates = (state = INIT_STATE, action) => {
    switch(action.type) {

        case SET_RATE: {
          return {
              ...state,
              Rate: action.payload
          }
        }

        case EDIT_RATE: {
            return {
                ...state,
                editMasterRate: action.payload
            }
        }

        case SET_MASTER_RATES: {
            return {
                ...state,
                MasterRates: action.payload
            }
        }

        default:
        return state;
    }
}

export default rates;