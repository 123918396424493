import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import configureStore, { history } from './store';
import App from './containers/App';
import { InstallerProvider } from './actions/InstallerProvider'
import { ScheduleProvider } from './actions/ScheduleProvider';
import { CreateScheduleProvider } from './actions/CreateScheduleProvider';
import { CreateQuoteProvider } from './actions/CreateQuoteProvider';
import { WorkOrderProvider } from './actions/WorkOrderProvider';
import { AppProvider } from './actions/AppProvider';

export const store = configureStore();

const MainApp = () =>
    <Provider store={store}>
      <AppProvider>
         <CreateQuoteProvider>
            <InstallerProvider>     
              <ScheduleProvider>
                <CreateScheduleProvider>
                  <WorkOrderProvider>
                <HashRouter>
                    <Switch>
                        <Route path="/" component={App} />
                    </Switch>
                </HashRouter>
                  </WorkOrderProvider>
                </CreateScheduleProvider>
              </ScheduleProvider>
            </InstallerProvider>
          </CreateQuoteProvider>
      </AppProvider>
    </Provider>;


export default MainApp;