import { userSignOut } from './Auth';
import {NotificationManager} from 'react-notifications'; 
import { ResponsiveContainer } from 'recharts';

export function authenticatedFetch(state, url, method, body, contentType = "application/json") {
    let user = state.auth.authUser;
    
    return fetch(url, {  
        method,
        "headers": {
            "content-type": contentType,
            "x-access-token": user.token
        },
        body
      }).then(response => {
        if (response.status === 401) {
            NotificationManager.error('Failed','User Session has expired!', 2000);
            userSignOut();
        } else 
            return response;
      });
}

export function authFetchHook(user, url, method, body, contentType = "application/json") {
    return fetch(url, {
        method,
        "headers": {
            "content-type": contentType,
            "x-access-token": user.token
        },
        body
    }).then(response => {
        if(response.status === 401) {
            NotificationManager.error('Failed','User Session has expired!', 2000);
            userSignOut();
        }
        
        if(response.ok) {

            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json();
            }else {
                return response.text()
            }

       }else {
        NotificationManager.error('Failed','Error From Back Server', 2000);
            console.log('bad response')
       }
    })
}