import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Settings';
import Auth from './Auth';
import Quotes from './Quotes';
import Users from './Users';
import Conditions from './Conditions';
import Contacts from './Contacts';
import Customers from './Customers';
import JobSites from './JobSites';
import Rates from './Rates';
import EndUser from './Enduser';
import Installers from './Installers';
import Schedule from './Schedule';
import AuditLogs from './AuditLog';
import { reducer as formReducer } from 'redux-form';


const createRootReducer =(history) => combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    quotes: Quotes,
    contacts: Contacts,
    endusers: EndUser,
    customers: Customers,
    installers: Installers,
    users: Users,
    jobsites: JobSites,
    rates: Rates,
    conditions: Conditions,
    schedule: Schedule,
    auditlogs: AuditLogs,
    form: formReducer
});

export default createRootReducer;