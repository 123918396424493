import {
    GET_JOBSITES,
    JOBSITE_NAMES,
    SAVE_JOBSITE,
    RESET_JOBSITE,
    JOB_SITE_EDIT
} from 'constants/ActionTypes';

const INIT_STATE = {
    allJobSites: null,
    allJobSiteNames: [' '],
    savedJobSite: false,
    editJobSite: {}
};

const contacts = (state = INIT_STATE, action) => {
    switch(action.type) {
        case GET_JOBSITES: {
            return {
                ...state,
                allJobSites: action.payload
            }
        }

        case JOB_SITE_EDIT: {
            return {
                ...state, 
                editJobSite: action.payload
            }
        }

        case JOBSITE_NAMES: {
            return {
                ...state,
                allJobSiteNames: action.payload
            }
        }

        case SAVE_JOBSITE: {
            return {
                ...state,
                savedJobSite: true,
                jobSite: action.payload
            }
        }

        case RESET_JOBSITE: {
            return {
                ...state,
                savedJobSite: false
            }
        }

        default:
        return state;
    }
}

export default contacts;