import {
    GET_CONTACTS,
    CONTACT_NAMES,
    SELECT_CONTACT,
    SAVE_ENDUSER,
    SAVE_BILLTO,
    RESET_ENDUSER,
    RESET_BILLTO,
    EDIT_CONTACT,
    SAVING_CONTACT,
    DONE_SAVING_CONTACT,
    CONTACT_EDIT_LIST,
} from 'constants/ActionTypes';

const INIT_STATE = {
    allContacts: null,
    allContactNames:  [' '],
    savedEndUser: false,
    billToSaved: false,
    endUser: {},
    billTo: {},
    editContact: {},
    isContactSaving: false,
    contactEditList: {},
};

const contacts = (state = INIT_STATE, action) => {
    switch(action.type) {
        case GET_CONTACTS: {
            return {
                ...state,
                allContacts: action.payload
            }
        }

        case SAVE_ENDUSER: {
          return {
              ...state,
              endUser: action.payload,
              savedEndUser: true,
          }
        }

        case RESET_ENDUSER: {
          return {
              ...state,
              savedEndUser: false,
          }
        }

        case SAVE_BILLTO: {
            return {
              ...state,
              billTo: action.payload,
              billToSaved: true,
            }
        }
        
        case RESET_BILLTO: {
            return {
                ...state,
                billToSaved: false,
            }
        }

        case CONTACT_NAMES: {
            return {
                ...state,
                allContactNames: action.payload
            }
        }

        case SELECT_CONTACT: {
            return {
                ...state,
                selectedContact: action.payload
            }
        }

        case EDIT_CONTACT: {
            return {
                ...state,
                editContact: action.payload
            }
        }

        case SAVING_CONTACT: {
            return {
                ...state,
                isContactSaving: true,
            }
        }

        case DONE_SAVING_CONTACT: {
            return {
                ...state,
                isContactSaving: false,
            }
        }
        case CONTACT_EDIT_LIST: {
            return {
                ...state,
                contactEditList: action.payload,
            }
        }

        default:
        return state;
    }
}

export default contacts;