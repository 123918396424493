import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Link, NavLink, withRouter} from 'react-router-dom';
import {Button} from '@mui/material';
import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';

class SidenavContent extends Component {
    componentDidMount() {
        const {history} = this.props;
        const that = this;
        const pathname = `#${history.location.pathname}`;// get current path

        const subMenuLi = document.querySelectorAll('.sub-menu > a');
        for (let i = 0; i < subMenuLi.length; i++) {
            subMenuLi[i].onclick = function (event) {
                event.stopPropagation();
            };
        }

        const menuLi = document.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (event) {
                for (let j = 0; j < menuLi.length; j++) {
                    const parentLi = that.closest(this, 'li');
                    if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
                        menuLi[j].classList.remove('open');
                    }
                }
                this.classList.toggle('open');
                event.stopPropagation();
            }
        }

        const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] == 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (error) {

        }

        return null;
    }

    render() {
        const { user } = this.props;
        return (
            <CustomScrollbars className="scrollbar">
                <ul className="nav-menu">

                    <li className="nav-header"><IntlMessages id="sidebar.main" /></li>
                    <li className="menu no-arrow">
                        <NavLink to="/app/quotes-page">
                            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                            <span className="nav-text"><IntlMessages id="pages.quotesPage" /></span>
                        </NavLink>
                    </li>
                    <li className="menu">

                    {(user.role === 'admin') ?   
                    (<Button href="javascript:void(0)">
                            <i className="zmdi zmdi-wrench"></i>
                            <span className="nav-text">
                                Admin
                            </span>
                    </Button>) : null }    
                        <ul className="sub-menu">
                            <Link to="/app/admin/customers">
                                <li>
                                    <i className="zmdi zmdi-account"></i>
                                    <span className="nav-text">Manage Customers</span>
                                </li>
                            </Link>
                            <Link to="/app/admin/endusers">
                                <li>
                                    <i className="zmdi zmdi-account"></i>
                                    <span className="nav-text">Manage End Users</span>
                                </li>
                            </Link>
                            <Link to="/app/admin/contacts">
                                <li>
                                    <i className="zmdi zmdi-account"></i>
                                    <span className="nav-text">Manage Contacts</span>
                                </li>
                            </Link>
                            <Link to="/app/admin/users">
                                <li>
                                    <i className="zmdi zmdi-account"></i>
                                    <span className="nav-text">Manage Users</span>
                                </li>
                            </Link>
                            <Link to="/app/admin/conditions">
                                <li>
                                    <i className="zmdi zmdi-receipt"></i>
                                    <span className="nav-text">Manage Conditions</span>
                                </li>
                            </Link>
                            <Link to="/app/admin/rates">
                                <li>
                                    <i className="zmdi zmdi-receipt"></i>
                                    <span className="nav-text">Manage Master Rates</span>
                                </li>
                            </Link>
                            <Link to="/app/admin/installers">
                                <li>
                                    <i className="zmdi zmdi-receipt"></i>
                                    <span className="nav-text">Manage Installers</span>
                                </li>
                            </Link>
                             <Link to="/app/admin/dispatch">
                                <li>
                                    <i className="zmdi zmdi-receipt"></i>
                                    <span className="nav-text">Dispatch</span>
                                </li>
                            </Link>
                            <Link to="/app/admin/email">
                                <li>
                                    <i className="zmdi zmdi-receipt"></i>
                                    <span className="nav-text">Sent Email</span>
                                </li>
                            </Link>
                            <Link to="/app/admin/attachments">
                            <li>
                                    <i className="zmdi zmdi-receipt"></i>
                                    <span className="nav-text">Attachments</span>
                                </li>
                            </Link>
                        </ul>
                    </li>

                    <li className="menu">

                    {(user.role === 'dispatch' ) ?   
                    (<Button href="javascript:void(0)">
                            <i className="zmdi zmdi-wrench"></i>
                            <span className="nav-text">
                                Admin
                            </span>
                    </Button>) : null }    
                        <ul className="sub-menu">
                             <NavLink to="/app/admin/dispatch">
                                <li>
                                    <i className="zmdi zmdi-receipt"></i>
                                    <span className="nav-text">Dispatch</span>
                                </li>
                            </NavLink>
                        </ul>
                    </li>
                </ul>
            </CustomScrollbars>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.authUser,
    };
}

export default connect(mapStateToProps)(withRouter(SidenavContent));
