// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';

//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';

// Chat Module const

export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Mail Module const


export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';


//// Qutoes

export const SET_CURRENT_QUOTE = 'set_current_quote';
export const NEW_QUOTE = 'new_quote';
export const SEARCH_QUOTES = 'search_quotes';
export const GET_QUOTES = 'get_quotes';
export const SEARCHING = 'SEARCHING';
export const SAVE_QUOTE = "save_quote";
export const UPDATE_QUOTE =  "update_quote";
export const QUOTE_ACTION_FAIL ="quote_action_failed";
export const RESET_SAVE_QUOTE_SUCCESS = "reset_save_quote_success";
export const RESET_SAVE_QUOTE_FAIL = "reset_save_quote_fail";
export const SAVING_QUOTE = 'saving_quote';
export const GENERATE_QUOTE_PDF = "generate_quote_pdf";
export const QUOTE_SAVING = "QUOTE_SAVING";
export const DONE_SAVING = "DONE_SAVING";
export const TOP_ID = "TOP_ID";
export const BOTTOM_ID = "BOTTOM_ID";
export const IS_SEARCHING = "IS_SEARCHING";
export const SUMMARY_QUOTE = "summaryQuote";


//// Contacts

export const SAVE_ENDUSER = "save_enduser";
export const SAVE_BILLTO = "save_billto";
export const SAVE_CONTACT_FAIL = "save_contact_fail";
export const GET_CONTACTS = "get_contacts";
export const GET_CONTACTS_FAIL = "get_contacts_fail";
export const SELECT_CONTACT = "select_contact";
export const CONTACT_NAMES = "contact_names";
export const RESET_CONTACT = 'RESET_CONTACT';
export const RESET_ENDUSER = "RESET_ENDUSER";
export const RESET_BILLTO = 'RESET_BILLTO';
export const EDIT_CONTACT = 'EDIT_CONTACT';
export const SAVING_CONTACT = "SAVING_CONTACT";
export const DONE_SAVING_CONTACT = "DONE_SAVING_CONTACT";
export const CONTACT_EDIT_LIST = "CONTACT_EDIT_LIST";


//// CUSTOMERS

export const GET_CUSTOMERS = "get_customers";
export const SELECT_CUSTOMER = "select_customer";
export const CUSTOMER_NAMES = "customer_names";
export const SAVE_CUSTOMER = 'SAVE_CUSTOMER';
export const RESET_CUSTOMER = 'RESET_CUSTOMER';
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
export const CUSTOMER_SAVING = 'CUSTOMER_SAVING';
export const CUSTOMER_DONE_SAVING = 'CUSTOMER_DONE_SAVING';
export const CUSTOMER_EDIT_LISTS = 'CUSTOMER_EDIT_LISTS';


//// Users

export const GET_USERS = 'GET_USERS';
export const GET_USER_BY_ID = "GETUSERBYID";
export const UPDATE_USER = "UPDATE_USER";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_FORM = "UPDATE_FORM";
export const CLEAR_USER_FORM = "CLEAR_USER_FORM";
export const CLEAR_SUCCESS_TAG = "CLEAR_SUCCESS_TAG";


//// CONDITIONS

export const GET_CONDITIONS = 'GET_CONDITIONS';
export const ADD_CONDITION = "ADD_CONDITION";
export const REMOVE_CONDITION = "REMOVE_CONDITION";
export const SET_CONDITIONS = "SET_CONDITIONS";
export const CREATE_CONDITION = 'CREATE_CONDITION';
export const UPDATE_CONDITION = 'UPDATE_CONDITION';
export const SAVED_QUOTE = "SAVED_QUOTE";
export const CONDITIONS_SAVED = "CONDITIONS_SAVED";

//// JOBSITE

export const GET_JOBSITES = 'GET_JOBSITES';
export const JOBSITE_NAMES = 'JOBSITE_NAMES';
export const SAVE_JOBSITE = 'SAVE_JOBSITE';
export const RESET_JOBSITE = 'RESET_JOBSITE';
export const JOB_SITE_EDIT = 'JOB_SITE_EDIT';

export const EDIT_CONDITION = 'EDIT_CONDITION';


///RATES

export const SET_RATE = 'SET_RATE';
export const EDIT_RATE = 'EDIT_RATE';
export const SET_MASTER_RATES = 'SETMASTERRATES';


//END USERS
export const GET_ENDUSERS = "GET_ENDUSERS";
export const ENDUSER_NAMES = "ENDUSER_NAMES"; 
export const SELECT_ENDUSER = "SELECT_ENDUSER";
export const END_USER_EDIT = 'END_USER_EDIT';
export const END_USER_EDIT_LIST = "END_USER_EDIT_LIST";

//INSTALLERS
export const GET_INSTALLERS = "GET_INSTALLERS";
export const SELECT_INSTALLER = "SELECT_INSTALLER";
export const EDIT_INSTALLER = "EDIT_CUSTOMER";
export const SELECTED_INSTALLERS = "SELECTED_INSTALLERS";
export const AVAIL_INSTALLER_DATA = "AVAIL_INSTALLER_DATA";
export const SCHED_INSTALLER_DATA = "SCHED_INSTALLER_DATA";
export const INSTALLER_STOPS = "INSTALLER_STOPS";
export const INSTALLER_SCHEDULES = "INSTALLER_SCHEDULES";
export const WORKING_INSTALLER = "WORKING_INSTALLER";
export const WORKING_INSTALLER_DATA = "WORKING_INSTALLER_DATA";
export const RESET_INSTALLERS = "RESET_INSTALLERS";
export const INSTALLER_EDIT_LIST = "INSTALLER_EDIT_LIST";

//SCHEDULE

export const GET_UNSCHEDULED = "GET_UNSCHEDULED"
export const GET_SCHEDULED = "GET_SCHEDULED"
export const GET_SAVED = "GET_SAVED"
export const EDIT_SCHEDULE = "EDIT_SCHEDULE"
export const EDIT_SAVE_SCHEDULE = "EDIT_SAVE_SCHEDULE"
export const SET_SCHEDULE_DATE = "SET_SCHEDULE_DATE"
export const SET_SCHEDULE_QUOTEID = "SET_SCHEDULE_QUOTEID"
export const AVAIL_INSTALLERS= "AVAIL_INSTALLERS"
export const DAY_SCHEDULE = "DAY_SCHEDULE"
export const ALL_SCHEDULED = "ALL_SCHEDULED"
export const EMAIL_AUDIT = "EMAIL_AUDIT"
export const GET_ATTACHMENTS = "GET_ATTACHMENTS"
export const ALL_ATTACHMENTS = "ALL_ATTACHMENTS"

//AUDIT LOGS

export const GET_LOGS = "GET_LOGS"
export const ALL_LOGS = "ALL_LOGS"

