import React, {useEffect} from 'react';
import {AppBar, Toolbar} from '@mui/material';
import {IconButton} from '@mui/material';
import SearchBox from 'components/SearchBox';
import Menu from 'components/TopNav/Menu';
import { Redirect } from 'react-router';
import config from '../../../package.json';
import {
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
    INSIDE_THE_HEADER
} from 'constants/ActionTypes';
import { useCreateQuoteContext } from '../../actions/CreateQuoteProvider';
import { useAppContext } from '../../actions/AppProvider';
import { Link } from 'react-router-dom';


function Header () {

   const {doRedirect,searchText,searchBox,setSearchText,setSearchBox,setDoRedirect,headerRedirect, setHeaderRedirect, searchTerm,searchQuotes,setSearchTerm} = useCreateQuoteContext()
   const {drawerType, locale, navigationStyle, horizontalNavPosition} = useAppContext()

    
  useEffect(() => {
   setDoRedirect(false)
   setHeaderRedirect(false)
  }, [])
   const  updateSearchText = (evt) => {
        evt.preventDefault();
        setSearchTerm(evt.target.value)
    }

    const doSearch = async(e) => {
      e.preventDefault()
      await searchQuotes(searchTerm);
      await searchQuotes(searchTerm);
      if(window.location.pathname.indexOf('/quotes-page') === -1 )
        setHeaderRedirect(true)
    }
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';
    

    return (
           
            <AppBar
                className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>
                    {headerRedirect && <Redirect push to="/app/quotes-page" />}
                <Toolbar className="app-toolbar" disableGutters={false}>
                    {navigationStyle === HORIZONTAL_NAVIGATION ?
                        <div className="d-block d-md-none pointer mr-3" >
                            <span className="jr-menu-icon">
                              <span className="menu-icon"/>
                            </span>
                        </div>
                        :
                        <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
                                    >
                            <span className="menu-icon"/>
                        </IconButton>
                    }

                    <Link className="app-logo mr-2 d-none d-sm-block" to="/" >
                        <img src="../../assets/images/midinclogo.png" alt="Midinc" title="Midinc"/>
                    </Link>


                    <SearchBox styleName="d-none d-lg-block" placeholder=""
                               onChange={e => updateSearchText(e)}
                               onSearchClick={e => doSearch(e)} 
                               value={searchTerm}/>
                    {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER) &&
                    <Menu/>}

                    <div style={{color: '#fff'}}>Version: {config.version}</div>
                </Toolbar>
            </AppBar>
        );
    }
export default Header