import {
 GET,
 SELECT_INSTALLER,
 EDIT_INSTALLER,
 SET_SCHEDULE_DATE,
 SET_SCHEDULE_QUOTEID,
 GET_SCHEDULED,
 GET_UNSCHEDULED,
 GET_INSTALLERS,
 EDIT_SCHEDULE,
 SHOW_MESSAGEM,
 SELECTED_INSTALLERS,
 SCHED_INSTALLER_DATA,
 DAY_SCHEDULE,
 ALL_SCHEDULED,
 EDIT_SAVE_SCHEDULE,
 EMAIL_AUDIT,
} from 'constants/ActionTypes';
import React from 'react';
import {NotificationManager} from 'react-notifications';  
import config from '../config/config';
import { authenticatedFetch } from './AuthenticatedFetch';
import moment from 'moment';
import { ALL_ATTACHMENTS, GET_ATTACHMENTS, GET_SAVED } from '../constants/ActionTypes';
import {
  Button
} from 'reactstrap';



export function deleteSchedule(id) {
    return async(dispatch, getState) => { 
      let state = getState();
      authenticatedFetch(state, `${config.apiPath}/schedule/deleteSchedule/${id}`, "DELETE")
        .then(response => {
          if (response.status === 200) {
              NotificationManager.success("Installer Deleted", "Success", 2000);
            authenticatedFetch(state, `${config.apiPath}/schedule/installers`, "GET")
            .then(response => {
              return response.json().then(installers => ({
                data: installers,
                status: response.status
              }));
            })
              .then(response => {
                if (response.status === 200) {
                  dispatch({ type: GET_INSTALLERS, payload: response.data });
                } else {
                  NotificationManager.error(
                    "Failed",
                    "Error getting Installers",
                    2000
                  );
                }
              });
          } else {
            NotificationManager.error("Failed", "Error Deleting Installer " + cType, 2000);
          }
        });
    }
  }

  export function editSchedule(installer) {
      return async(dispatch) => {

        dispatch({type: EDIT_INSTALLER, payload: installer});
      }
  }

  export function setScheduleDate(date) {

    return async(dispatch) => {
      dispatch({type: SET_SCHEDULE_DATE, payload: date});

    }
  }

  export function setScheduleQuoteid(quoteId,date,projectName) {

    return async(dispatch) => {
      let quoteToSchedule = {
        quoteId: quoteId,
        date: date,
        projectName: projectName
      }
      dispatch({type: SET_SCHEDULE_QUOTEID, payload: quoteToSchedule});

    }
  }

  export function setEditQuoteSchedule(quote) {
    return async(dispatch, getState) => {
        dispatch({type: EDIT_SCHEDULE,  payload:quote})
    } 
  }

  export function setEditSaveSched(sched) {
    return async(dispatch, getState) => {
      dispatch({type: EDIT_SAVE_SCHEDULE,  payload:sched})
  } 
  }

  export function deleteScheduleSave(id) {
      return async(dispatch,getState) => {
        let state = getState();
        await authenticatedFetch(state, `${config.apiPath}/schedule/removeSaved/`+id,"DELETE")
        .then(response=> {
            if(response.status === 200) {
              NotificationManager.success("Schedule Deleted", "Success", 2000);
            }else {
              NotificationManager.error("Issue Deleting Schedule ", "Error", 2000);
            }
        } )
      }
  }

  export function editScheduleSave(sched) {
    return async(dispatch,getState) => {
      let state = getState();
      await authenticatedFetch(state, `${config.apiPath}/schedule/updateSaved/`,"PUT",JSON.stringify(sched))
        .then(response=> {
            if(response.status === 200) {
              NotificationManager.success("Schedule Saved", "Success", 2000);
            }else {
              NotificationManager.error("Issue Saving Schedule ", "Error", 2000);
            }
        } )
    }
  }
  

  export function createSchedule(schedule) {

 

  	return async(dispatch, getState) => {
      let state = getState();
      let body = JSON.stringify(schedule);
      

      await authenticatedFetch(state, `${config.apiPath}/schedule/saveSched`, "POST", body)
        .then(response => {
          if (response.status === 200) {
            NotificationManager.success("Schedule Saved", "Success", 2000);
            authenticatedFetch(state, `${config.apiPath}/schedule/installers`, "GET")
              .then(response => {
               
                dispatch({type: SELECTED_INSTALLERS, payload:[]});
                dispatch({type: SCHED_INSTALLER_DATA, payload:[]});
                return response.json().then(installers => ({
                  data: installers,
                  status: response.status
                }));

              })
              .then(response => {
                if (response.status === 200) {
                  dispatch({ type: GET_INSTALLERS, payload: response.data });
                } else {
                  NotificationManager.error(
                    "Failed",
                    "Error getting Installers",
                    2000
                  );
                }
              });
          } else {
            NotificationManager.error("Failed", "Error saving " + cType, 2000);
          }
        });
    } 
  }

  export function unscheduleQuote(id) {
    return async(dispatch, getState) => {
      let state = getState();
      authenticatedFetch(state, `${config.apiPath}/schedule/unschedule/${id}`, "DELETE")
        .then(response => {
          if (response.status === 200) {
            NotificationManager.success("Unscheduled Quote", "Success", 2000);
        
          } else {
            NotificationManager.error("Failed", "Error " + cType, 2000);
          }
        });
    } 
  }

  export function saveNewSchedule(schedule) {
    return async(dispatch, getState) => {
      let state = getState();

      authenticatedFetch(state, `${config.apiPath}/schedule/save`, "POST", JSON.stringify(schedule))
      .then(response => {
        if (response.status === 200) {
          NotificationManager.success("Schedule Saved", "Success", 2000);
      
        } else {
          NotificationManager.error("Failed to Save Schedule", "Error " + cType, 2000);
        }
      });
    }
  }

  export function editNewSchedule(schdule) {


  }

  export function saveEditSchedule(schedule) {
    return async(dispatch, getState) => {
      let state = getState();
      let body = JSON.stringify(schedule);
      authenticatedFetch(state, `${config.apiPath}/schedule/editSchedule`, "PUT", body)
        .then(async response => {

             await authenticatedFetch(state, `${config.apiPath}/schedule/installers`, "GET")
              .then(response => {
                return response.json().then(installers => ({
                  data: installers,
                  status: response.status
                }));
              })
              .then(response => {
                if (response.status === 200) {
                  dispatch({ type: GET_INSTALLERS, payload: response.data });
                } else {
                  NotificationManager.error(
                    "Failed",
                    "Error getting Installers",
                    2000
                  );
                }
              });
          if (response.status === 200) {
            NotificationManager.success("Schedule Saved", "Success", 2000);
        
          } else {
            NotificationManager.error("Failed", "Error saving " + cType, 2000);
          }
        });
    } 
  }

export function getScheduledInstallers () {

    return async(dispatch,getState)  =>{
        let state = getState();
        
        authenticatedFetch(state, `${config.apiPath}/schedule/scheduled`, "GET")
        .then(response => {
              return response.json().then(schedules => ( {
                  data:installers,
                  status: response.status
              }));
          })
          .then(response => {
              if(response.status === 200) { 
             
                dispatch({type: GET_INSTALLERS, payload: response.data});
              }else{
                NotificationManager.error('Failed','Error getting installers', 2000);
              }
          });
    };
}

export function getUnScheduledInstallers () {

    return async(dispatch,getState)  =>{
        let state = getState();
        
        authenticatedFetch(state, `${config.apiPath}/schedule/unscheduled/${new Date()}`, "GET")
        .then(response => {
              return response.json().then(schedules => ( {
                  data:installers,
                  status: response.status
              }));
          })
          .then(response => {
              if(response.status === 200) { 
             
                dispatch({type: GET_INSTALLERS, payload: response.data});
              }else{
                NotificationManager.error('Failed','Error getting installers', 2000);
              }
          });
    };
}

export function getEditSchedule(id) {
  return async (dispatch, getState) => {
    let state = getState();

  await authenticatedFetch(state, `${config.apiPath}/schedule/editschedule/${id}`, "GET")
      .then(response => {
        return response.json().then(quote => ({
          data: quote,
          status: response.status
        }));
      })
      .then(response => {
        if (response.status === 200) {
      
         dispatch({type: EDIT_SCHEDULE,  payload:response.data})
         return true;
          
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: "Error Getting Quotes"
          });

          return false;
        }
      })
      .catch(err => {
        console.log(err);
      });
  };


}

export function getDaySchedule() { 
  return async(dispatch, getState) => {
    let state = getState();
    console.log('sched size is ', state.schedule.allScheduled)
    if(state.schedule.allScheduled) {

      let daysevents = [];
      console.log('sched size is ', state.schedule.allScheduled)
      await state.schedule.allScheduled.map(sched => {
        if (sched.start_time && sched.start_time.includes(':')) {
            let time = sched.start_time.split(':');
            var today = moment(sched.date).format('DD-MMM-YYYY');
            console.log('today is ', today)
            var dt = moment( today + " " + sched.start_time, 'DD-MMM-YYYY hh:mm A')

          
            daysevents.push({
                start: new Date(dt),
                end: new Date(dt.add(1, "hours")),
                title: sched.projectname
            })
        }
    })
    dispatch({type: DAY_SCHEDULE, payload: daysevents})
    } 
  }
}

export function getScheduled(date) {
    return async(dispatch, getState) => {
    let state = getState();

    authenticatedFetch(state, `${config.apiPath}/schedule/find/${date}`, "GET")
      .then(response => {
        return response.json().then(quotes => ({
          data: quotes,
          status: response.status
        }));
      })
      .then(response => {
        if (response.status === 200) {
          console.log('scheduled is ', response.data)
          return response.data
          
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: "Error Getting Quotes"
          });

          return false;
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
}


export function getSaved(date) {
  return async(dispatch, getState) => {
  let state = getState();

  authenticatedFetch(state, `${config.apiPath}/schedule/saved/${date}`, "GET")
    .then(response => {
      return response.json().then(quotes => ({
        data: quotes,
        status: response.status
      }));
    })
    .then(response => {
      if (response.status === 200) {
        console.log('SAVED IS', response.data)
       return response.data
      } else {
        dispatch({
          type: SHOW_MESSAGE,
          payload: "Error Getting Quotes"
        });

        return false;
      }
    })
    .catch(err => {
      console.log(err);
    });
};
}

export function getAllScheduled() {
  return async(dispatch, getState) => {
    let state = getState();
    
    authenticatedFetch(state, `${config.apiPath}/schedule/all`, "GET")
    .then(response => {
      return response.json().then(scheds =>({data: scheds, status: response.status}));
    }).then(response => {
      if (response.status === 200) {
        let daysevents = [];
        if(response.data) {
        response.data.scheduled.map(sched => {
          if (sched.start_time && sched.start_time.includes(':')) {
              let time = sched.start_time.split(':');
              var today = moment(sched.date).format('DD-MMM-YYYY');
              console.log('today is ', today)
              var dt = moment( today + " " + sched.start_time, 'DD-MMM-YYYY hh:mm A')
  
            
              daysevents.push({
                  start: new Date(dt),
                  end: new Date(dt.add(1, "hours")),
                  title: `${sched.projectname} ${sched.start_time}`, 
                  backgroundColor: sched.color ? sched.color: 'blue'
            })
          }
      })
      response.data.saved.map(sched => {
        if (sched.start_time && sched.start_time.includes(':')) {
            let time = sched.start_time.split(':');
            var today = moment(sched.date).format('DD-MMM-YYYY');
            console.log('today is ', today)
            var dt = moment( today + " " + sched.start_time, 'DD-MMM-YYYY hh:mm A')

          
            daysevents.push({
                start: new Date(dt),
                end: new Date(dt.add(1, "hours")),
                title: `(saved) ${sched.projectname} ${sched.start_time}`, 
                backgroundColor: sched.color ? sched.color: 'blue'
          })
        }
    })
    
    }
        

        dispatch({ type: ALL_SCHEDULED, payload: response.data });
        dispatch({type: DAY_SCHEDULE, payload: daysevents});
      } 
    }).catch(err => {
      console.log(err);
    });
  }
}

export function sortSchedule(schedules, sortBy, sortDirection, actionType) {
  return async(dispatch, getState) => {
    if (sortDirection === 'ASC') {
      schedules.sort((a, b) => {

        if (a[sortBy] > b[sortBy]) {
          return 1
        }

        if (a[sortBy] < b[sortBy]) {
          return -1
        }

      })

    } else {
      schedules.sort((a, b) => {

        if (a[sortBy] < b[sortBy]) {
          return 1
        }

        if (a[sortBy] > b[sortBy]) {
          return -1
        }

      })
    }
    dispatch({ type: actionType, payload: schedules });
  }
}

export function filterSchedule(schedules, searchString, actionType) {
  return(dispatch) => {
  let filtered = [];
  console.log('search strign is', searchString)
  if(searchString === "" ) {
    dispatch({ type: actionType, payload: schedules });
  }
  
  schedules.forEach(sched => {
    if(sched.projectname.indexOf(searchString) != -1) {
      filtered.push(sched);
    }
  })
  dispatch({ type: actionType, payload: filtered });
}

}

export function setAttachments(attchm) {
  return(dispatch) => {
    dispatch({type: ALL_ATTACHMENTS, payload: attchm})
  }
}

export function getAttachments(id) {

  return (dispatch, getState) => {
    let state = getState();
    authenticatedFetch(state, `${config.apiPath}/schedule/attachments/` + id, "GET")
    .then(response => {
      return response.json().then(audits => ({
        data: audits,
        status: response.status
      }));
    })
    .then(response => {
      
      dispatch({type: GET_ATTACHMENTS, payload: response.data})

    });
  }
}



export function getAllAttachments(rmvFunc) {

  return (dispatch, getState) => {
    let state = getState();
    authenticatedFetch(state, `${config.apiPath}/schedule/allattachments`, "GET")
    .then(response => {
      return response.json().then(audits => ({
        data: audits,
        status: response.status
      }));
    })
    .then(response => {

      let dta = response.data;
      dta.forEach(rw => {
        let id = rw.id;
        rw.remove = <Button onClick={(e) => {rmvFunc(id)}}>Remove</Button>
      });

      console.log('ALL DATA IS ', dta)

      let attachMentData = {
        columns: [
          {
            label: 'Id',
            field: 'id',
            sort: 'asc',
            width: 150
        },
            {
                label: 'QuoteId',
                field: 'QuoteId',
                sort: 'asc',
                width: 150
            },
            {
                label: 'FileName',
                field: 'fileName',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Type',
                field: 'type',
                sort: 'asc',
                width: 150
            },
            {
              label: '',
              field: 'remove'
            }
          
          
        ],
        rows: dta
    }
      
      dispatch({type: ALL_ATTACHMENTS, payload: attachMentData})

    });
  }
}

export function removeAttachment( id, qid) {
  return async(dispatch,getState) => {
    let state = getState();
    authenticatedFetch(state, `${config.apiPath}/schedule/attachments/` + id, "DELETE")
    .then(response => {
      return response.json().then(audits => ({
        data: audits,
        status: response.status
      }));
    })
    .then(response => {

     if(response.status === 200) {
      
        authenticatedFetch(state, `${config.apiPath}/schedule/attachments/` + qid, "GET")
        .then(resp => {
          return resp.json().then(audits => ({
            data: audits,
            status: resp.status
          }));
        })
        .then(resp => {
          dispatch({type: GET_ATTACHMENTS, payload: resp.data})
        });
        NotificationManager.success('Success', 'Attachments Removed', 2000);
        
     }else {
        NotificationManager.error('Failed','Error removing Attachments', 2000);
     }

    });
  }
}

export function cleanAttachment( id) {
  return async(dispatch,getState) => {
    let state = getState();
    authenticatedFetch(state, `${config.apiPath}/schedule/cleanAttachment/` + id, "DELETE")
    .then(response => {
      return response.json().then(audits => ({
        data: audits,
        status: response.status
      }));
    })
    .then(response => {

     if(response.status === 200) {
        NotificationManager.success('Success', 'Attachments Removed', 2000);
     }else {
        NotificationManager.error('Failed','Error removing Attachments', 2000);
     }

    });
  }
}

export function downloadAttachment(id) {
  return async(dispatch, getState) => {
    let state = getState();
    await authenticatedFetch(state, `${config.apiPath}/schedule/download/` + id, "GET",null,"base64");
  
  }
}

export function saveAttachments( attacthments, id) {
  return async(dispatch,getState) => {
    let state = getState();
    authenticatedFetch(state, `${config.apiPath}/schedule/attachments/` + id, "PUT",JSON.stringify(attacthments) )
    .then(response => {
      return response.json().then(audits => ({
        data: audits,
        status: response.status
      }));
    })
    .then(response => {

     if(response.status === 200) {
      authenticatedFetch(state, `${config.apiPath}/schedule/attachments/` + id, "GET")
      .then(resp => {
        return resp.json().then(audits => ({
          data: audits,
          status: resp.status
        }));
      })
      .then(response => {
        
        dispatch({type: GET_ATTACHMENTS, payload: response.data})
        NotificationManager.success('Success', 'Attachments Saved', 2000);

  
      });
        
     }else {
        NotificationManager.error('Failed','Error Saving Attachments', 2000);
     }

    });
  }
}

export function getEmailAudit() {
  return (dispatch, getState) => {
    let state = getState();
    authenticatedFetch(state, `${config.apiPath}/schedule/emailAudit`, "GET")
      .then(response => {
        return response.json().then(audits => ({
          data: audits,
          status: response.status
        }));
      })
      .then(response => {
        if (response.status === 200) {
          console.log('Audit resp is ', response.data)

          response.data.forEach(dta => {
            dta.body = <div dangerouslySetInnerHTML={{__html:dta.body}}></div> 
          })

          let emailData = {
            columns: [
                
                {
                    label: 'QuoteId',
                    field: 'quoteId',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Email',
                    field: 'toEmail',
                    sort: 'asc',
                    width: 150
                },
                {
                  label: 'Body',
                  field: 'body',
                  sort: 'asc',
                  width: 150
              },
                {
                  label: 'When',
                  field: 'createdAt',
                  sort: 'asc',
                  width: 150
              },
              
            ],
            rows: response.data
        }
        
        dispatch({ type: EMAIL_AUDIT, payload: emailData });
      
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: "Error Getting Quotes"
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
}


export function getUnScheduled(date) {
  return async(dispatch, getState) => {
    let state = getState();

    authenticatedFetch(state, `${config.apiPath}/quotes/unsched/${date}`, "GET")
      .then(response => {
        return response.json().then(quotes => ({
          data: quotes,
          status: response.status
        }));
      })
      .then(response => {
        if (response.status === 200) {
          return response.data
      
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: "Error Getting Quotes"
          });

          return false;
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
}