
import React,{ createContext, useState, useContext } from "react";
import { FIXED_DRAWER, INSIDE_THE_HEADER, VERTICAL_NAVIGATION } from "../constants/ActionTypes";

const appProviderTemplate = {
    navCollapsed: false,
    setNavCollapsed: () => {},
    drawerType: FIXED_DRAWER,
    setDrawerType: () => {},
    width: window.innerWidth,
    setWidth: () => {},
    darkTheme: true,
    setDarkTheme: () => {},
    isDirectionRTL: false,
    setIsDirectionRTL: () => {},
    navigationStyle: null,
    setNavigationStyle: () => {},
    horizontalNavPosition: null,
    setHorizontalNavPosition: () => {},
    locale: null,
    setLocale: () => {}
}
export const AppContext = createContext(appProviderTemplate)
export const useAppContext = () => useContext(AppContext)
export const AppProvider = ({children}) => {

    const[navCollapsed,setNavCollapsed] = useState(false)
    const[drawerType, setDrawerType] = useState(FIXED_DRAWER)
    const[width, setWidth] = useState(window.innerWidth)
    const[darkTheme, setDarkTheme] = useState(true)
    const[isDirectionRTL,setIsDirectionRTL] = useState(false)
    const[navigationStyle,setNavigationStyle] = useState(VERTICAL_NAVIGATION)
    const[horizontalNavPosition, setHorizontalNavPosition] = useState(INSIDE_THE_HEADER)
    const[locale, setLocale] = useState({
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us'
    })


    return (<AppContext.Provider value={{navCollapsed,setNavCollapsed,drawerType,setDrawerType,width,setWidth,darkTheme,setDarkTheme,isDirectionRTL,setIsDirectionRTL,navigationStyle,setNavigationStyle,horizontalNavPosition,setHorizontalNavPosition,locale,setLocale}}>
        {children}
    </AppContext.Provider>)
}





