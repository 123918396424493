import {
    GET_LOGS,
    ALL_LOGS
} from 'constants/ActionTypes';

const INIT_STATE = {
    auditLogs: [],
    allLogs: []
};

const auditlogs = (state = INIT_STATE, action) => {
    switch(action.type) {
        case GET_LOGS: {
            return {
                ...state,
                auditLogs: action.payload
            }
        }

        case  ALL_LOGS: {
            return {
                ...state, 
                allLogs: action.payload
            }
        }

        default:
        return state;
    }
}

export default auditlogs;