import { CatchingPokemonSharp, InstallDesktopRounded, TurnLeft } from '@mui/icons-material';
import config from '../config/config';
import React,{createContext, useState, useContext} from 'react';
import { NotificationManager } from 'react-notifications';
import { authFetchHook } from './AuthenticatedFetch';
import { useSelector } from 'react-redux';


const scheduleTemplate = {
    unscheduled: [],
    scheduled: [],
    saved:[],
    unscheduledFiltered: [],
    scheduledFiltered:[],
    savedFiltered:[],
    schedDate: null,
    filterVar: null,
    openScheduleModal: false,
    createSched: null,
    showSavedScheduleModal: false,
    savedEdit: null,
    scheduledEdit: null,
    showEditScheduleModal: false,
    setFilterVar: () => {},
    getUnscheduled: () =>{},
    sortSchedule: () => {},
    setSchedDate: () => {},
    filterSchedule: () => {},
    toggleScheduleModal: () => {},
    setCreateSched: () => {},
    getScheduled: () => {},
    getSaved: () => {},
    finishQuote: () => {},
    toggleSavedModal: () => {},
    setSavedEdit: () => {},
    deleteSaved: () => {},
    editSavedSchedule: () => {},
    setScheduledEdit: () => {},
    editSchedule: () => {},
    unSchedule: () => {},
    toggleEditModal: () => {},
    setSortDirection: () => {},
    setSortBy: () => {},
    getNextUnscheduled: async () => {},
    getPrevUnscheduled: async () => {},
    checkQuote: async () => {},
    searchUnscheduled: async () => {},
    searchUnscheduledNext: async () => {},
    searchUnscheduledPrev: async () => {}
}

export const ScheduleContext = createContext(scheduleTemplate);

export const useScheduleContext = () => useContext(ScheduleContext)

export const ScheduleProvider = ({children}) => {
    const [unscheduled,setUnscheduled] = useState([])
    const [scheduled, setScheduled] = useState([])
    const [saved, setSaved] = useState([])
    const [scheduledFiltered, setScheduledFiltered] = useState([])
    const [savedFiltered, setSavedFiltered] = useState([])
    const [unscheduledFiltered, setUnscheduleFiltered] = useState([])
    const [sortDirection, setSortDirection] = useState("ASC")
    const[schedDate,setSchedDate] = useState(null)
    const[filterVar, setFilterVar] = useState(null)
    const[openScheduleModal,setOpenScheduleModal] = useState(false)
    const [createSched,setCreateSched] = useState(null)
    const user = useSelector(state => state.auth)
    const [showSavedScheduleModal, setShowSavedScheduleModal] = useState(false)
    const [savedEdit, setSavedEdit] = useState(null)
    const [scheduledEdit, setScheduledEdit] = useState(null)
    const [showEditScheduleModal, setShowEditScheduleModal] = useState(false)
    const [sortBy,setSortBy] = useState(null)
    const [lastId, setLastId] = useState(null)
    const [firstId, setFirstId] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [searchPageNumber, setSearchPageNumber] = useState(1)
    


  

    const getUnscheduled = async(date) => {
        setUnscheduleFiltered([])
        setUnscheduled([])
        let unsched = await authFetchHook(user.authUser,`${config.apiPath}/quotes/unsched/${date}`, "GET")
        .then(json => { return json }
        )
        if(unsched && unsched.length > 0) {
          setFirstId(unsched[0].id)
          setLastId(unsched[unsched.length -1].id)
        }
        setUnscheduled(unsched)
        return new Promise( (resolve) => resolve(true))
    }

    const getNextUnscheduled = async(date) => {
      setPageNumber(pageNumber +1)
      setUnscheduleFiltered([])
        setUnscheduled([])
        let unsched = await authFetchHook(user.authUser,`${config.apiPath}/quotes/unschedNext/${date}/${pageNumber+1}`, "GET")
        .then(json => { return json }
        )
        if(unsched && unsched.length > 0) {
          setFirstId(unsched[0].id)
          setLastId(unsched[unsched.length -1].id)
        }
        setUnscheduled(unsched)
        return new Promise( (resolve) => resolve(true))
    }

    const getPrevUnscheduled = async(date) => {
      if(pageNumber != 1) {
        setPageNumber(pageNumber -1)
      setUnscheduleFiltered([])
        setUnscheduled([])
        let unsched = await authFetchHook(user.authUser,`${config.apiPath}/quotes/unschedPrev/${date}/${pageNumber-1}`, "GET")
        .then(json => { return json }
        )
        if(unsched && unsched.length > 0) {
          setFirstId(unsched[0].id)
          setLastId(unsched[unsched.length -1].id)
        }
        setUnscheduled(unsched)
        return new Promise( (resolve) => resolve(true))
      }
    }


    const editSavedSchedule = async(sched) => {

      let resp  = await authFetchHook(user.authUser, `${config.apiPath}/schedule/updateSaved/`,"PUT",JSON.stringify(sched)).then(resp => {
        NotificationManager.success("Schedule Saved", "Success", 2000)
      })

      return new Promise( (resolve) => resolve(true))

    } 

    const editSchedule = async(sched,closeFunc) => {
      let resp  = await authFetchHook(user.authUser,`${config.apiPath}/schedule/editSchedule`, "PUT", JSON.stringify(sched)).then(resp => {
        NotificationManager.success("Schedule Edited", "Success", 2000)
        closeFunc()
      })
    }

    const unSchedule = async(id, closeFunc) => {
      let resp  = await authFetchHook(user.authUser,`${config.apiPath}/schedule/unschedule/${id}`, "DELETE").then(resp => {
        NotificationManager.success("Unscheduled Quote", "Success", 2000)
        closeFunc()
        
      })
      return new Promise(resolve => resolve(true))
    }



    const getScheduled= async(date) => {
      setScheduledFiltered([])
      setScheduled([])
      let scheds = await authFetchHook(user.authUser,`${config.apiPath}/schedule/find/${date}`, "GET")
        .then(json => { return json }
        )
        setScheduled(scheds)
        return new Promise( (resolve) => resolve(true))
    }

    const getSaved = async(date) => {
      setSavedFiltered([])
      setSaved([])
      let svd = await authFetchHook(user.authUser,`${config.apiPath}/schedule/saved/${date}`, "GET")
        .then(json => { return json }
        )
        setSaved(svd)
        return true
    }

   const sortSchedule = (sortBy, actionType,schedules) => {
        let sdir = sortDirection
        if("ASC" === sdir) {  
          setSortDirection("DESC")
          sdir = "DESC"
        }else {
          setSortDirection("ASC")
          sdir = "ASC"
        }
          if (sdir === 'ASC') {
            schedules.sort((a, b) => {
      
              if (a[sortBy] > b[sortBy]) {
                return 1
              }
      
              if (a[sortBy] < b[sortBy]) {
                return -1
              }
      
            })
      
          } else {
            schedules.sort((a, b) => {
      
              if (a[sortBy] < b[sortBy]) {
                return 1
              }
      
              if (a[sortBy] > b[sortBy]) {
                return -1
              }
      
            })
          }

          switch(actionType) {
            case 'UNSCHED' : {
                setUnscheduleFiltered(schedules)
                break
            }
            case 'SVD' : {
              setSavedFiltered(schedules)
              break
            }

            case 'SCHD' :{
              setScheduledFiltered(schedules)
            }
          }
         
      }

      const searchUnscheduled = async (date, term) => {
        let body = JSON.stringify({ searchTerm: term });
       var filtered  = await authFetchHook(user.authUser, `${config.apiPath}/quotes/unschedSearch/${date}`,"POST",body)
       setUnscheduleFiltered(filtered)
      }

      const searchUnscheduledNext = async (date, term) => {
        console.log('search term is', filterVar)
        let body = JSON.stringify({ searchTerm: filterVar });
        

        var filtered  = await authFetchHook(user.authUser, `${config.apiPath}/quotes/unschedSearchNext/${date}/${searchPageNumber +1}`,"POST",body)
        if(filtered && filtered.length > 0) {
          setUnscheduleFiltered(filtered)
          setSearchPageNumber(searchPageNumber +1)
        }
        
       }

       const searchUnscheduledPrev = async (date, term) => {
        let body = JSON.stringify({ searchTerm: filterVar });
        if(searchPageNumber -1 !== 0) { 
        var filtered  = await authFetchHook(user.authUser, `${config.apiPath}/quotes/unschedSearchPrev/${date}/${searchPageNumber -1}`,"POST",body)
        if(filtered && filtered.length > 0) {
          setUnscheduleFiltered(filtered)
          setSearchPageNumber(searchPageNumber -1)
        }
       }

       
       }

    const filterSchedule = (type) =>  {
        switch(type) {
            case  'UN': {
               
               
                setUnscheduleFiltered(filtered)
                return true
            }

            case  'SVD': {
              var filtered = []
              saved.map(svd => {
              if (svd.projectname.toUpperCase().indexOf(filterVar.toUpperCase()) != -1) {
                  filtered.push(svd)
                }
                if (svd.id.toString().indexOf(filterVar) != -1) {
                  filtered.push(svd)
                }   
              })
              
              setSavedFiltered(filtered)
              return new Promise( (resolve) => resolve(true))
          }
          case  'SCHD': {
            var filtered = []
            scheduled.map(sch => {
            if (sch.projectname.toUpperCase().indexOf(filterVar.toUpperCase()) != -1) {
                filtered.push(sch)
              }
              if (sch.id.toString().indexOf(filterVar) != -1) {
                filtered.push(sch)
              }   
            })
            
            setScheduledFiltered(filtered)
            return new Promise( (resolve) => resolve(true))
        }
        }
    }
    
    const deleteSaved = async(id) => {
      let resp = await authFetchHook(user.authUser, `${config.apiPath}/schedule/removeSaved/`+id,"DELETE").then(resp =>
        NotificationManager.success("Schedule Deleted", "Success", 2000) 
        )
        return new Promise( (resolve) => resolve(true))
    }


    const toggleScheduleModal = () => {
        setOpenScheduleModal(!openScheduleModal)
    }

    const toggleSavedModal = () => {
        setShowSavedScheduleModal(!showSavedScheduleModal)
    }

    const toggleEditModal = () => {
      setShowEditScheduleModal(!showEditScheduleModal)
    }

    const checkQuote = async(id) => {
      var message = ""
      
      let isSaved = await authFetchHook(user.authUser,`${config.apiPath}/quotes/issaved/` + id, "GET" )
      .then(resp =>  {
        console.log('saved resp is', resp)
        if(resp.length > 0) {
          resp.forEach( rsp => {
            message += 'saved on ' + rsp.date + '\r\n'
          })
        }
      
      })

      
      let isScheduled = await authFetchHook(user.authUser,`${config.apiPath}/quotes/issched/` + id, "GET" )
      .then(resp =>  {
        console.log('sched resp is', resp)
        if(resp.length > 0) {
          resp.forEach( rsp => {
            message += 'scheduled on ' + rsp.date + '\r\n'
          })
        }
      
      })
      return message
    }

    const finishQuote = async(id) => {
    
        let resp = await authFetchHook(user.authUser,`${config.apiPath}/quotes/finish/` + id, "GET" )
        .then(resposne =>  NotificationManager.success('Success', 'Finished Quote', 2000))

        return new Promise( (resolve) => resolve(true))
    }

    return (<ScheduleContext.Provider value={{unscheduled,scheduled,saved,unscheduledFiltered,scheduledFiltered, savedFiltered, schedDate, getUnscheduled, getNextUnscheduled, getPrevUnscheduled, sortSchedule, setSchedDate,filterVar,setFilterVar, filterSchedule, openScheduleModal, toggleScheduleModal, createSched,setCreateSched,getUnscheduled,getSaved, getScheduled, finishQuote, toggleSavedModal, showSavedScheduleModal, savedEdit, setSavedEdit, 
    deleteSaved, editSavedSchedule, scheduledEdit, setScheduledEdit, editSchedule, unSchedule, showEditScheduleModal, 
    toggleEditModal, setSortDirection, setSortBy, checkQuote,
    searchUnscheduled,
    searchUnscheduledPrev,
    searchUnscheduledNext
    }}>
        {children}
    </ScheduleContext.Provider>)
}