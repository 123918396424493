import React from 'react';
import { Input } from 'reactstrap';

const renderField = ({ input, label, type, meta: { active, touched, error, submitFailed } }) => (
  <div>
    <label>{label}</label>
    <div>
      <Input {...input} placeholder={label} type={type} autoComplete='off' />
      {!active && touched && (error && submitFailed) && <span style={{ color: 'red' }}>{error}</span>}
    </div>
  </div>
)

const renderReadOnlyField = ({ input, label, type, meta: { active, touched, error, submitFailed } }) => (
  <div>
    <label>{label}</label>
    <div>
      <Input {...input} placeholder={label} type={type} autoComplete='off' readOnly disabled />
      {!active && touched && (error && submitFailed) && <span style={{ color: 'red' }}>{error}</span>}
    </div>
  </div>
)


const renderSelectField = ({ input, label, type, meta: { active, touched, error, submitFailed }, children }) => (
  <div>
    <label>{label}</label>
    <div>
      <select {...input} className="form-control">
        {children}
      </select>
      {!active && touched && (error && submitFailed) && <span style={{ marginLeft: '10px', color: 'red' }}>{error}</span>}
    </div>
  </div>
);

export default function buildNameArray(namedArray) {
  let names = [];
  namedArray.map(nmd => {
    if (nmd.name !== null && typeof nmd.name !== "undefined") {
      names.push(nmd.name);
    } else if (nmd.address !== null && typeof nmd.address !== "undefined") {
      names.push(nmd.address);
    }
  });
  return names.sort(function (a, b) {
    var nameA = a.toLowerCase(), nameB = b.toLowerCase();
    if (nameA < nameB) //sort string ascending
      return -1;
    if (nameA > nameB)
      return 1;
    return 0; //default return value (no sorting)
  });
};

export function isEmptyObject(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export function checkNumeric(val) {
  var reg = /^$|^[0-9.]+$/
  return reg.test(val);
}

export function intervalStart(ms, saveFunc) {
  setInterval(saveFunc, ms);
}

export function intervalStop(clearFunc) {
  clearInterval(clearFunc);
}

export { renderField, renderSelectField, renderReadOnlyField };