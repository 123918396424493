import {
    SET_CURRENT_QUOTE,
    NEW_QUOTE,
    SEARCH_QUOTES,
    SEARCHING,
    SAVE_QUOTE,
    RESET_SAVE_QUOTE_SUCCESS,
    RESET_SAVE_QUOTE_FAIL,
    QUOTE_ACTION_FAIL,
    UPDATE_QUOTE,
    SAVING_QUOTE,
    GENERATE_QUOTE_PDF,
    CONDITIONS_SAVED,
    QUOTE_SAVING,
    DONE_SAVING,
    TOP_ID,
    BOTTOM_ID,
    GET_QUOTES,
    SUMMARY_QUOTE
} from 'constants/ActionTypes';

const INIT_STATE = {
    activeQuotes: [],
    currentQuote: null,
    summaryQuote: null,
    isSearching: false,
    isSaving: false,
    quotePdf: null,
    conditionsSaved: false,
    bottomId: 0,
    topId: 0,
    searchTerm: '',
};


const quotes = (state = INIT_STATE, action) => {

    switch (action.type) {

        case SET_CURRENT_QUOTE: {
            return {
                ...state,
                loader: false,
                currentQuote: action.payload,
                newQuote: false,
            }
        }

        case DONE_SAVING: {
            return {
                ...state,
                isSaving: false,
            }
        }

        case SUMMARY_QUOTE: {
            return {
                ...state,
                summaryQuote: action.payload
            }
        }

        case SEARCHING: {
            return {
                ...state,
                isSearching: action.payload
            }
        }

        case NEW_QUOTE: {
            return {
                ...state,
                loader: false,
                currentQuote: action.payload,
                newQuote: false,
            }
        }

        case SEARCH_QUOTES: {
            return {
                ...state,
                loader: false,
                searchTerm: action.payload.term,
                activeQuotes: action.payload.data,
            }
        }


        case GET_QUOTES: {
            return {
                ...state,
                loader: false,
                searchTerm: null,
                activeQuotes: action.payload,
            }
        }

        case TOP_ID: {
            return {
                ...state,
                topId: action.payload
            }
        }

         case BOTTOM_ID: {
            return {
                ...state,
                bottomId: action.payload
            }
        }

        case QUOTE_SAVING: {
            return {
                ...state,
                isSaving: true,
            }
        }

        case SAVE_QUOTE: {
            return {
                ... state,
                loader: false,
                currentQuote: action.payload,
                saveQuoteSuccess:true,
                newQuote: false,
                isSaving:false,

            }
        }

        case QUOTE_ACTION_FAIL: {
            return {
                ... state,
                loader: false,
                saveQuoteFail:true,
            }
        }

        case RESET_SAVE_QUOTE_SUCCESS: {
            return {
                ... state,
                saveQuoteSuccess:false,
            }
        }

        case RESET_SAVE_QUOTE_FAIL: {
            return {
                ... state,
                saveQuoteFail:false,
            }
        }

        case UPDATE_QUOTE: {
            return {
                ...state,
                currentQuote: action.payload,
                saveQuoteSuccess: true,
                isSaving:false,
            }
        }

        case SAVING_QUOTE: {
            return {
                ...state,
                isSaving:true,
            }
        }

        case GENERATE_QUOTE_PDF: {
            return {
                ...state,
                quotePdf: action.payload
            }
        }

        case CONDITIONS_SAVED: {
            return {
                ...state,
                conditionsSaved: action.payload,
            }
        }

        default:
            return state;

    }
}

export default quotes;