import {
    GET_UNSCHEDULED,
    GET_SCHEDULED,
    GET_SAVED,
    EDIT_SCHEDULE,
    SET_SCHEDULE_DATE,
 SET_SCHEDULE_QUOTEID,
 DAY_SCHEDULE,
 ALL_SCHEDULED,
 EDIT_SAVE_SCHEDULE,
 EMAIL_AUDIT,
} from 'constants/ActionTypes';
import { ALL_ATTACHMENTS, GET_ATTACHMENTS } from '../constants/ActionTypes';

const INIT_STATE = {
    unScheduled:  null,
    scheduled: null,
    savedScheduled: null,
    quoteSchedule: {},
    quoteToSchedule: {},
    daySchedule: [],
    allScheduled: null,
    editSaveSched: null,
    emailAudits: null,
    attachments: null,
    allAttachments: null,

};

const schedule = (state = INIT_STATE, action) => {
    switch(action.type) {
        case GET_UNSCHEDULED: {
            let unSch = action.payload;
            return {
                ...state,
                unScheduled: unSch
            };
        }

        case GET_SCHEDULED: {
            let sched = action.payload;
            return {
                ...state,
                scheduled: sched
            };
        }

        case GET_SAVED: {
            let sched = action.payload;
            return {
                ...state,
                savedScheduled: sched
            };
        }

        case  EDIT_SCHEDULE: {
            return {
                ...state,
                quoteSchedule: action.payload
            }
        }

        case EDIT_SAVE_SCHEDULE: {
            return {
                ...state,
                editSaveSched: action.payload
            }
        }

        case SET_SCHEDULE_DATE: {
            return {
                 ...state,
                scheduleDate: action.payload
            }
        }

        case SET_SCHEDULE_QUOTEID: {
            return {

                 ...state,
                quoteToSchedule: action.payload
            }
        }

        case DAY_SCHEDULE: {
            return {
                ...state,
                daySchedule: action.payload
            }
        }

        case ALL_SCHEDULED: {
            return {
                ...state,
                allScheduled: action.payload
            }
        }

        case EMAIL_AUDIT: {
            return {
                ...state,
                emailAudits: action.payload
            }
        }

        case GET_ATTACHMENTS: {
            return {
                ...state,
                attachments: action.payload
            }
        }
        case ALL_ATTACHMENTS: {
            return {
                ...state,
                allAttachments : action.payload
            }
        }

        default:
        return state;
    }
}

export default schedule;