import {
  GET_INSTALLERS,
  SELECT_INSTALLER,
  EDIT_INSTALLER,
  AVAIL_INSTALLERS,
  SELECTED_INSTALLERS,
  AVAIL_INSTALLER_DATA,
  SCHED_INSTALLER_DATA,
  INSTALLER_STOPS,
  INSTALLER_SCHEDULES,
  WORKING_INSTALLER,
  WORKING_INSTALLERS,
  WORKING_INSTALLER_DATA,
  RESET_INSTALLERS,
  INSTALLER_EDIT_LIST,
} from 'constants/ActionTypes';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import config from '../config/config';
import { authenticatedFetch } from './AuthenticatedFetch';
import moment from 'moment';
import { Edit} from '@mui/icons-material';
import {
  Button
} from 'reactstrap';
import { getSaved } from './Schedule';
import { forEach } from 'lodash';


export function deleteInstaller(id) {
  return (dispatch, getState) => {
    let state = getState();
    authenticatedFetch(state, `${config.apiPath}/schedule/deleteInstaller/${id}`, "DELETE")
      .then(response => {
        if (response.status === 200) {
          NotificationManager.success("Installer Deleted", "Success", 2000);
          authenticatedFetch(state, `${config.apiPath}/schedule/installers`, "GET")
            .then(response => {
              return response.json().then(installers => ({
                data: installers,
                status: response.status
              }));
            })
            .then(response => {
              if (response.status === 200) {
                dispatch({ type: GET_INSTALLERS, payload: response.data });
              } else {
                NotificationManager.error(
                  "Failed",
                  "Error getting Installers",
                  2000
                );
              }
            });
        } else {
          NotificationManager.error("Failed", "Error Deleting Installer ", 2000);
        }
      });
  }
}



export function sortInstallers(installers, sortBy, sortDirection) {
  return (dispatch, getState) => {
    if (sortDirection === 'ASC') {
      installers.sort((a, b) => {

        if (a[sortBy] > b[sortBy]) {
          return 1
        }

        if (a[sortBy] < b[sortBy]) {
          return -1
        }

      })

    } else {
      installers.sort((a, b) => {

        if (a[sortBy] < b[sortBy]) {
          return 1
        }

        if (a[sortBy] > b[sortBy]) {
          return -1
        }

      })
    }
    dispatch({ type: GET_INSTALLERS, payload: installers });
  }
}



export function editInstaller(installer) {
  return (dispatch) => {

    dispatch({ type: EDIT_INSTALLER, payload: installer });
  }
}

export function createInstaller(installer) {

  return (dispatch, getState) => {
    let state = getState();
    let body = JSON.stringify(installer);
    authenticatedFetch(state, `${config.apiPath}/schedule/saveInstaller`, "POST", body)
      .then(response => {
        if (response.status === 200) {
          NotificationManager.success("Installer Saved", "Success", 2000);
          authenticatedFetch(state, `${config.apiPath}/schedule/installers`, "GET")
            .then(response => {
              return response.json().then(installers => ({
                data: installers,
                status: response.status
              }));
            })
            .then(response => {
              if (response.status === 200) {
                dispatch({ type: GET_INSTALLERS, payload: response.data });
              } else {
                NotificationManager.error(
                  "Failed",
                  "Error getting Installers",
                  2000
                );
              }
            });
        } else {
          NotificationManager.error("Failed", "Error saving Installer ", 2000);
        }
      });
  }

}

export function saveEditInstaller(installer) {
  return (dispatch, getState) => {
    let state = getState();
    let body = JSON.stringify(installer);
    authenticatedFetch(state, `${config.apiPath}/schedule/editInstaller/${installer.id}`, "PUT", body)
      .then(response => {
        if (response.status === 200) {
          NotificationManager.success("Installer Saved", "Success", 2000);
          authenticatedFetch(state, `${config.apiPath}/schedule/installers`, "GET")
            .then(response => {
              return response.json().then(installers => ({
                data: installers,
                status: response.status
              }));
            })
            .then(response => {
              if (response.status === 200) {
                dispatch({ type: GET_INSTALLERS, payload: response.data });
              } else {
                NotificationManager.error(
                  "Failed",
                  "Error getting Installers",
                  2000
                );
              }
            });
        } else {
          NotificationManager.error("Failed", "Error saving " + cType, 2000);
        }
      });
  }
}

export function resetInstallers()
 {
  return (dispatch) => {
    dispatch({type: RESET_INSTALLERS});
  }
 }




export async function getAvailableInstallers(userToken, date) {
  
  
    let stops = await fetch(`${config.apiPath}/schedule/installerstops/` + date, {
      "method": "GET",
      "headers": {
        "content-type":  "application/json",
        "x-access-token": userToken.token
    }
    }).then(response => {
     return response.data
    })
    console.log('stops are', stops)
      
    let installers = await fetch(`${config.apiPath}/schedule/installers`, {  
      "method": "GET",
      "headers": {
          "content-type":  "application/json",
          "x-access-token": userToken.token
      }
    }).then(response => {
      if (response.status === 401) {
          NotificationManager.error('Failed','User Session has expired!', 2000);
          userSignOut();
      } else 
          return response.data;
    });
    if(stops) {
    for(let i =0; i < stops.length; i++) {
      for(let y =0; y < installers.length; y++) {
        if(stops[i].InstallerId === installer[y].id) {
          console.log('FOUND ONE')
          installers[y]["stops"] = stops[i].stops;
        }
      }

    }
   }

    return installers;
    
}

export function setWorkingInstallerData(installers, schedFunc, removeFunc, foreman, viewFunc, date) {
  return (dispatch, getState) => {
    let state = getState()

    dispatch({ type: WORKING_INSTALLER_DATA, payload: getAvailableInstallerData(state.installers.workingInstallers, schedFunc, removeFunc, foreman) })
  }
}

export function setAvailableInstallerData(installers, schedFunc, removeFunc, foremanFunc,viewFunc,date) {

  return (dispatch, getState) => {
    let state = getState()

    dispatch({ type: AVAIL_INSTALLER_DATA, payload: getAvailableInstallerData(state.installers.availInstallers, schedFunc, removeFunc, foremanFunc) })
  }

}


export function scheduleInstaller(installer, scheduleFunc, removeFunc, foremanFunc, viewFUNC) {

  return (dispatch, getState) => {
    let state = getState()
    let selectedInstallers = state.installers.selectedInstallers;
    let avInst = state.installers.availInstallers;
    if (selectedInstallers && selectedInstallers.length > 0) {
      for (let y = 0; y < selectedInstallers.length; y++) {
        if (installer.id === selectedInstallers[y].id) {
          console.log('already scheduled')
          return false;
        }
      }
    }
    for (let i = 0; i < avInst.length; i++) {
      if (avInst[i].id === installer.id) {
        avInst.splice(i, 1);
      }
    }
    dispatch({type: WORKING_INSTALLER_DATA, payload: getAvailableInstallerData(avInst, scheduleFunc, removeFunc, foremanFunc)})
   // dispatch({ type: AVAIL_INSTALLERS, payload: availInstallers })

   // dispatch({ type: AVAIL_INSTALLER_DATA, payload: getAvailableInstallerData(avInst, scheduleFunc, removeFunc, foremanFunc) })
    if (!selectedInstallers) {
      selectedInstallers = [];
    }
    selectedInstallers.push(installer);

    dispatch({ type: SELECTED_INSTALLERS, payload: selectedInstallers })
    dispatch({ type: SCHED_INSTALLER_DATA, payload: getSelectedInstallerData(selectedInstallers, scheduleFunc, removeFunc, foremanFunc) })

  }
}

export function setScheduledInstallers(installers, scheduleFunc, removeFunc, foremanFunc) {
  return (dispatch) => {
    dispatch({ type: SELECTED_INSTALLERS, payload: installers })

    dispatch({ type: SCHED_INSTALLER_DATA, payload: getSelectedInstallerData(installers, scheduleFunc, removeFunc, foremanFunc) })
  }
}

export function addSelectedInstaller(installerId) {
  console.log('trying to add ',installerId )
  return (dispatch,getState) => {
        let state = getState()
        let instl = {};
        console.log()
        state.installers.availInstallers.forEach((inst) => {
            if(inst.id === installerId) {
              instl = inst
            }
        })
        let selected = state.installers.selectedInstallers;
        console.log('SELECTED IS', selected)
        selected.push(instl)
        dispatch({type: SELECTED_INSTALLERS, payload: selected});
  }
}


function getAvailableInstallerData(installers, schedFunc, removeFunc, formanFunc) {
  

    if(!installers) {
      return null;
    }
    installers.forEach(installer => {
      installer.add = <Button onClick={(e) => {

        schedFunc(installer, schedFunc, removeFunc, formanFunc)
  
      }}>Add</Button>;
    
    })

    console.log('AFTER ADD ', installers);

  let installerData = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Rank',
        field: 'rank',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Stops',
        field: 'stops',
        sort: 'asc',
        width: 60
      },
      {
        label: 'Notes',
        field: 'notes',
        sort: 'asc',
        width: 300
      },
      {
        label: '',
        field: 'add'
      }
    ],
    rows: installers
  }
  return installerData;

}

export function setForman(id, schedFunc, removeFunc, formanFunc) {
  return (dispatch, getState) => {
    let state = getState();
    console.log('im here')
    let selected = state.installers.selectedInstallers;
    if (selected && selected.length > 0) {
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].id === id) {
          console.log('setting ', selected[i])
          selected[i]["isForeman"] = true
        } else {
          selected[i]["isForeman"] = false
        }
      }
    }

    dispatch({ type: SELECTED_INSTALLERS, payload: selected })

    dispatch({ type: SCHED_INSTALLER_DATA, payload: getSelectedInstallerData(selected, schedFunc, removeFunc, formanFunc) })

  }
}

function getSelectedInstallerData(installers, scheduleFunc, removeFunc, foremanFunc) {

  let selectedWithButtons = installers;
  for (let x = 0; x < selectedWithButtons.length; x++) {
    selectedWithButtons[x]["remove"] = <Button onClick={(e) => {
      removeFunc(selectedWithButtons[x].id, scheduleFunc, removeFunc, foremanFunc)

    }}>X</Button>

    selectedWithButtons[x]["foreman"] = <Button onClick={(e) => {

      foremanFunc(selectedWithButtons[x].id, scheduleFunc, removeFunc, foremanFunc)

    }}>F</Button>

    if (selectedWithButtons[x].isForeman) {
      selectedWithButtons[x]["fman"] = "Y"
    } else {
      selectedWithButtons[x]["fman"] = "N"
    }
  }

  let scheduledInstallerData = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Rank',
        field: 'rank',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Notes',
        field: 'notes',
        sort: 'asc',
        width: 300
      },
      {
        label: 'Forman?',
        field: 'fman',
        sort: 'acs',
        width: 75
      },
      {
        label: '',
        field: 'foreman',
        sort: 'acs',
        width: 75
      },
      {
        label: '',
        field: 'remove',
        sort: 'acs',
        width: 75
      }
    ],
    rows: selectedWithButtons
  }

  return scheduledInstallerData

}


export function removeInstaller(id, scheduleFunc, removeFunc, formanFunc,viewFunc, date) {
  return (dispatch, getState) => {
    console.log('removing')
    let state = getState();
    let addback = {};
    let selected = state.installers.selectedInstallers;
    if (selected && selected.length > 0) {
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].id === id) {
          addback = selected[i]
          selected.splice(i, 1)
        }
      }
    }
    addback["isForeman"] = false;
    let availInst = state.installers.availInstallers;
    availInst.push(addback);
    dispatch({ type: AVAIL_INSTALLERS, payload: availInst })

    dispatch({ type: AVAIL_INSTALLER_DATA, payload: getAvailableInstallerData(availInst, scheduleFunc, removeFunc, formanFunc) })

    dispatch({ type: SELECTED_INSTALLERS, payload: selected })

    dispatch({ type: SCHED_INSTALLER_DATA, payload: getSelectedInstallerData(selected,scheduleFunc, removeFunc, formanFunc) })

  }
}

export function getInstallerData(id,date) {
  return async(dispatch, getState) => {
    let state = getState();

    await authenticatedFetch(state, `${config.apiPath}/schedule/installerData/${id}/${date}`)
    .then(response => {
      return response.json().then(insts => ({
        data: insts,
        status: response.status
      }));
      }).then(response => {
        if(response.status === 200) {
          dispatch({ type: INSTALLER_SCHEDULES, payload: response.data });
        }else {
          NotificationManager.error('Failed', 'Error getting installer schedules', 2000);
        }
      })
  
  }
}

export function getInstallers() {

  return (dispatch, getState) => {
    let state = getState();

    authenticatedFetch(state, `${config.apiPath}/schedule/installers`, "GET")
      .then(response => {
        return response.json().then(installers => ({
          data: installers,
          status: response.status
        }));
      })
      .then(response => {
        if (response.status === 200) {

          dispatch({ type: GET_INSTALLERS, payload: response.data });
        } else {
          NotificationManager.error('Failed', 'Error getting installers', 2000);
        }
      });
  };
}

export function getEditInstallers( editFunction) {

  return (dispatch, getState) => {
    let state = getState();

    authenticatedFetch(state, `${config.apiPath}/schedule/installers`, "GET")
      .then(response => {
        return response.json().then(installers => ({
          data: installers,
          status: response.status
        }));
      })
      .then(response => {
        if (response.status === 200) {

          for (let y = 0; y < response.data.length; y++) {
            response.data[y]["edit"] = <Edit onClick={(e) => {editFunction(response.data[y])}} />
          }
          let installerData = {
            columns: [
                {
                    label: '',
                    field: 'edit',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'ID',
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                  label: 'Name',
                  field: 'name',
                  sort: 'asc',
                  width: 150
                },
                {
                  label: 'Rank',
                  field: 'rank',
                  sort: 'asc',
                  width: 150
              },
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Mobile',
                    field: 'mobile',
                    sort: 'asc',
                    width: 150
                },
            ],
            rows: response.data
        }
          dispatch({ type: INSTALLER_EDIT_LIST, payload: installerData});

        } else {
          NotificationManager.error('Failed', 'Error getting installers', 2000);
        }
      });
  };
}