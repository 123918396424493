import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import UserCrud from 'components/UserCrud';
import {NotificationContainer} from 'react-notifications';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';
import { Modal,ModalHeader,ModalBody } from 'reactstrap';
import {
    ABOVE_THE_HEADER,
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';

class App extends React.Component {

    constructor() {
        super();
        this.state = {showUserInfo: false};
    }

    toggleUserInfo = () => {
        this.setState({showUserInfo: !this.state.showUserInfo});
    }
    
    render() {
        
        const {match, drawerType, navigationStyle, horizontalNavPosition} = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 
            'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height');
        }
        else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height');
        }
        console.log('pahtname is', this.props.location.pathname)
        let HideNav = this.props.location.pathname === '/app/admin/sched' ? null : <Sidebar toggleUser={this.toggleUserInfo} />
        let HidTopNavAppTop = this.props.location.pathname === '/app/admin/sched' ? null : <TopNav styleName="app-top-header"/>
        let HidTopNav  = this.props.location.pathname === '/app/admin/sched' ? null : <TopNav/>
        let HideHeader = this.props.location.pathname === '/app/admin/sched' ? null : <Header />
        return (
            <div className={`app-container ${drawerStyle}`}>
            <NotificationContainer/>
              
                {HideNav}
                <div className="app-main-container">
                    <div
                        className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                        {HidTopNavAppTop}}
                        {HideHeader }
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
                        {HidTopNav}}
                        <Modal isOpen={this.state.showUserInfo} toggle={this.toggleUserInfo}>
            <ModalHeader>
                {"User Profile"}
            </ModalHeader>
            <ModalBody>
                
            <UserCrud />
    
            </ModalBody>
            </Modal>
                    </div>

                    <main className="app-main-content-wrapper">
                        
                        <div className="app-main-content">
                            <Switch>
                                <Route exact path={`${match.url}/admin/users`} 
                                    component={asyncComponent(() => import('./routes/Admin/Users/UsersList'))} />
                                <Route exact path={`${match.url}/admin/conditions`} 
                                    component={asyncComponent(() => import('./routes/Admin/Conditions'))} />
                                <Route exact path={`${match.url}/admin/customers`} 
                                    component={asyncComponent(() => import('./routes/Admin/Customers'))} />
                                <Route exact path={`${match.url}/admin/endusers`}
                                    component={asyncComponent(() => import('./routes/Admin/EndUser'))} />    
                                <Route exact path={`${match.url}/admin/contacts`} 
                                    component={asyncComponent(() => import('./routes/Admin/Contacts'))} />
                                <Route exact path={`${match.url}/admin/rates`} 
                                    component={asyncComponent(() => import('./routes/Admin/MasterRates'))} />                                        
                                <Route exact path={`${match.url}/quotes-page`}
                                    component={asyncComponent(() => import('./routes/QuoteList'))}/>  
                                <Route exact path={`${match.url}/quote`}
                                    component={asyncComponent(() => import('./routes/Quote'))}/>   
                                <Route exact path={`${match.url}/admin/installers`}
                                    component={asyncComponent(() => import('./routes/Admin/Installers'))}/>
                                <Route exact path={`${match.url}/admin/dispatch`}
                                    component={asyncComponent(() => import('./routes/Quote/QuoteDispatch'))}/>  
                                <Route exact path={`${match.url}/admin/sched`}
                                    component={asyncComponent(() => import('./routes/Admin/DailySchedule'))}/>
                                <Route exact path={`${match.url}/admin/email`} 
                                    component={asyncComponent(() => import('./routes/Admin/EmailAudit'))} />    
                                  <Route exact path={`${match.url}/admin/attachments`} 
                                    component={asyncComponent(() => import('./routes/Admin/Attachments'))} />                          
                                <Route
                                    component={asyncComponent(() => import('../containers/SignIn.js'))}/>
                            </Switch>
                        </div>
                        <Footer/>
                    </main>
                </div>
                <Switch>
                <Route path={`${match.url}/admin/dayschedule`}
                                    component={asyncComponent(() => import('./routes/Admin/DailySchedule'))}/>                     
                                
                </Switch>   
            </div>

            
        );
    }
}


const mapStateToProps = ({settings}) => {
    const {drawerType, navigationStyle, horizontalNavPosition,authUser} = settings;
    return {drawerType, navigationStyle, horizontalNavPosition,authUser}
};

export default withRouter(connect(mapStateToProps)(App));